<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <EmployeesTable
        title="Бүртгэл"
        description="Энэxүү бүртгэл нь нийт ажилчдын бүртгэл. Үйлдлийн тусламжтай
                шинэ ажилтанг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                боломжтой."
        :path="path"
        addButtonTitle="Бүртгэл"
        :directGo="true"
        :showAddButton="true"
        :query="query"
        :headerNames="headerNames"
        :bodyNames="bodyNames"
      ></EmployeesTable>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import EmployeesTable from "./Widgets/EmployeesTable";
import { sync } from "vuex-pathify";
export default {
  components: {
    EmployeesTable,
  },
  data: () => ({ query: null, path: null, headerNames: null, bodyNames: null }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    if (this.userData.ppschool != null) {
      this.path = this.userData.ppschool + "/teachers";
      this.query = fb.db
        .collection(this.path)
        .where("deleted", "==", false)
        .orderBy("firstName", "asc");
    } else {
      this.path = "teachers";
      this.query = fb.db.collection(this.path).orderBy("firstName", "asc");
    }
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "Имэйл",
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: "ЗАН",
        align: "start",
        sortable: true,
        value: "department",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];
    this.bodyNames = ["firstName", "lastName", "email"];
  },
  methods: {
    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
 