<template>
  <v-app>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-container
      class="my-6 mx-auto scoreTables"
      style="max-width: 100% !important"
    >
      <v-row justify="start">
        <v-col lg="8" cols="12" md="8">
          <v-card class="border-radius-xl card-shadow">
            <div class="card-header-padding">
              <div class="d-flex flex-row">
                <div class="px-4">
                  <template v-if="lessonObject.classGroups">
                    <span
                      @click="selectedClassGroup = classGroup"
                      style="cursor: pointer"
                      class="mr-4"
                      v-for="(classGroup, cgindex) in lessonObject.classGroups"
                      :key="cgindex"
                    >
                      <span
                        v-if="
                          selectedClassGroup &&
                          selectedClassGroup.classGroupRef &&
                          selectedClassGroup.classGroupRef.path ==
                            classGroup.classGroupRef.path
                        "
                        class="font-weight-bold pb-2 px-4"
                        style="
                          color: #56ca28 !important;
                          border-bottom: 3px solid;
                        "
                        >{{
                          classGroup.departmentName + classGroup.classGroupName
                        }}
                        <span v-if="students3">({{ students3.length }})</span>
                      </span>
                      <span v-else class="pb-2 px-4"
                        >{{
                          classGroup.departmentName + classGroup.classGroupName
                        }}
                        <span v-if="students3">({{ students3.length }})</span>
                      </span>
                    </span></template
                  >
                  <span
                    v-if="this.lessonObject.isGroupBased == true"
                    @click="selectedClassGroup = null"
                  >
                    <span
                      v-if="selectedClassGroup == null"
                      class="font-weight-bold pb-2 px-4"
                      style="
                        cursor: pointer;
                        color: #56ca28 !important;
                        border-bottom: 3px solid;
                      "
                      >Групп
                      <span v-if="studentsOfGroup"
                        >({{ studentsOfGroup.length }})</span
                      ></span
                    >
                    <span v-else style="cursor: pointer" class="pb-2 px-4"
                      >Групп
                      <span v-if="studentsOfGroup"
                        >({{ studentsOfGroup.length }})</span
                      >
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <v-card-text class="mt-4">
              <v-data-table
                :headers="headers2"
                :items="students3"
                :items-per-page="-1"
                hide-details
                hide-default-footer
              >
                <template slot="item" slot-scope="props">
                  <tr>
                    <td style="width: 1%">{{ props.item.index }}</td>
                    <td>
                      <span class="font-weight-bold">
                        {{ props.item.firstName }}</span
                      >

                      <span v-if="props.item.lastName"
                        >, {{ props.item.lastName }}</span
                      >
                    </td>
                    <td
                      v-for="snum in 3"
                      :key="'semesterscore-' + snum"
                      style="width: 1%"
                    >
                      <input
                        :id="props.item.id + snum"
                        v-if="props.item.scores"
                        :placeholder="props.item.id"
                        type="number"
                        v-model.number="props.item.scores[snum - 1].finalScore"
                        @change="finalScoreChanged(props.item, snum)"
                        style="width: 60px"
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4">
          <v-card class="h-100 card-shadow border-radius-xl">
            <div class="px-4 py-4">
              <div class="chart">
                <v-card v-for="sn in semesterNumber" :key="'seme-' + sn">
                  <div class="px-4 py-4">
                    <h6 class="text-h6 text-typo font-weight-bold">
                       Улирлын дүн ({{ sn }})
                    </h6>
                    <div class="chart pt-4">
                      <canvas
                        :id="'chartsss-' + sn"
                        class="chart-canvas"
                        height="170"
                      ></canvas>
                    </div>
                  </div>
                </v-card>
              </div>
              <!-- <ScoreWidgetClassGroups></ScoreWidgetClassGroups> -->
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// import SessionScoreWidget from "./SessionScoreWidget";
import Chart from "chart.js/auto";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    // SessionScoreTable,
    // SessionScoreWidget,
    // ScoreWidgetClassGroups,
  },
  data: () => ({
    cccharts: [],
    actionstatus: null,
    snackbar: false,
    selectedClassGroup: null,
    xselectedClassGroup: null,
    currentSelectedLesson: null,
    tabClassGroups: 0,
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    studentsOfGroup: null, //
    students2: null, // students from classgroup
    semesterNumber: 3,
  }),
  props: {
    lessonObject: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },

    headers2() {
      var list = [];
      list.push({
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < this.semesterNumber; i++) {
        list.push({
          text: "УД-" + (i + 1),
          value: "score" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
    body2() {
      var list = [];
      var row;
      for (var k = 0; k < 300; k++) {
        row = {
          index: k + 1,
          firstName: "User-" + (k + 1),
        };
        for (var i = 1; i < 31; i++) {
          var xx = "val-" + (i + 1).toString();
          var tmp = {};
          tmp[xx] = i + 1;

          console.log(tmp);
          row[xx] = i + 1;
        }
        list.push(row);
      }

      return list;
    },
    students3() {
      var list = [];
      if (this.selectedClassGroup && this.students2) {
        var counter = 0;
        for (var xstudent of this.students2) {
          if (
            this.selectedClassGroup.classGroupRef &&
            this.selectedClassGroup.classGroupRef.path ==
              xstudent.classGroupRef.path &&
            xstudent.classGroupRef
          ) {
            counter++;
            xstudent.index = counter;
            list.push(xstudent);
          }
        }
      } else {
        if (this.selectedClassGroup == null && this.studentsOfGroup) {
          for (var student of this.studentsOfGroup) {
            list.push(student);
          }
        }
      }
      return list;
    },
    scoreChartData() {
      var xxx = [0, 0, 0, 0, 0, 0, 0, 0];
      if (this.students2) {
        for (var student of this.students2) {
          if (student.scores) {
            for (var score of student.scores) {
              if (score.semester == 1) {
                xxx[this.getXLevel(score.finalScore) - 1] =
                  xxx[this.getXLevel(score.finalScore) - 1] + 1;
                break;
              }
            }
          }
        }

        // for (var i = 0; i < this.students2.length; i++) {
        //   console.log(this.students2[i].scores);
        // }
      }
      console.log(xxx);
      return xxx;
    },
  },
  watch: {
    tabClassGroups(val) {
      // var x = this.currentSelectedLesson.classGroups[val].classGroupRef;
      console.log(val);
      // if (this.students[x.path] == undefined) {
      //   this.findStudents(x);
      // }
    },
    scoreChartData() {
      this.updatee();
    },
  },
  methods: {
    getXLevel(score) {
      if (100 >= score && score >= 90) {
        return 8;
      } else if (89 >= score && score >= 80) {
        return 7;
      } else if (79 >= score && score >= 70) {
        return 6;
      } else if (69 >= score && score >= 60) {
        return 5;
      } else if (59 >= score && score >= 50) {
        return 4;
      } else if (49 >= score && score >= 40) {
        return 3;
      } else if (39 >= score && score >= 30) {
        return 2;
      } else if (29 >= score && score >= 0) {
        return 1;
      }
    },
    async finalScoreChanged(student, semesterNumber) {
      this.lessonObject.ref
        .collection("studentList")
        .doc(student.id)
        .set({ scores: student.scores }, { merge: true })
        .then(() => {
          student.ref.collection("finalGrades").doc(this.lessonObject.id).set(
            {
              categoryRefPath: this.lessonObject.categoryRef,
              currentYear: 2021,
              scores: student.scores,
            },
            { merge: true }
          );
          this.actionstatus =
            student.firstName +
            ", " +
            student.scores[semesterNumber - 1].finalScore +
            " дүн амжилттай xадгалагдлаа.";
          this.snackbar = true;
          document.getElementById(student.id + semesterNumber).blur();
          this.updatee();
        });
    },
    findStudents(classGroupRef) {
      fb.db
        .collection(this.zzschool + "/students")
        .where("classGroup-2021", "==", classGroupRef)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            student.classGroupRef = classGroupRef;
            student.scores = null;

            this.lessonObject.ref
              .collection("studentList")
              .doc(student.id)
              .onSnapshot((doc) => {
                if (doc.exists) student.scores = doc.data().scores;
                else {
                  student.scores = [];
                  if (this.semesterNumber > 0) {
                    for (var k = 1; k <= this.semesterNumber; k++) {
                      student.scores.push({ finalScore: 0, semester: k });
                    }
                  }
                }
                console.log(student.scores);
              });

            this.students2.push(student);
          });
        });
    },
    updatee() {
      for (var xxx of this.cccharts) {
        xxx.destroy();
      }
      for (var i = 1; i <= this.semesterNumber; i++) {
        var cc = new Chart(
          document.getElementById("chartsss-" + i).getContext("2d"),
          {
            type: "bar",
            data: {
              labels: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII"],
              datasets: [
                {
                  label: "xxx",
                  tension: 0.4,
                  borderWidth: 0,
                  borderRadius: 4,
                  borderSkipped: false,
                  backgroundColor: "#3A416F",
                  data: this.scoreChartData,
                  maxBarThickness: 6,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              interaction: {
                intersect: false,
                mode: "index",
              },
              scales: {
                y: {
                  grid: {
                    drawBorder: false,
                    display: false,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                  },
                  ticks: {
                    display: true,
                    padding: 10,
                    color: "#9ca2b7",
                  },
                },
                x: {
                  grid: {
                    drawBorder: false,
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [5, 5],
                  },
                  ticks: {
                    display: true,
                    padding: 10,
                    color: "#9ca2b7",
                  },
                },
              },
            },
          }
        );
        this.cccharts.push(cc);
      }
      this.isThisFirst = false;
    },
  },
  created() {
    if (this.lessonObject != null)
      this.currentSelectedLesson = this.lessonObject;

    if (
      this.lessonObject.classGroups &&
      this.lessonObject.classGroups.length > 0
    ) {
      this.students2 = [];
      this.selectedClassGroup = this.lessonObject.classGroups[0];
      this.lessonObject.classGroups.forEach((doc) => {
        this.findStudents(doc.classGroupRef);
      });
    }

    if (this.lessonObject.isGroupBased == true) {
      this.lessonObject.ref
        .collection("lesson-students")
        .orderBy("firstName", "asc")
        .onSnapshot((docs) => {
          this.studentsOfGroup = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let gstudent = doc.data();
            gstudent.index = counter;
            gstudent.id = doc.id;
            gstudent.ref = doc.ref;
            gstudent.selectiveAdded = true;
            if (gstudent["departmentName-2021"]) {
              gstudent.className = gstudent["departmentName-2021"];
              if (gstudent["classGroupName-2021"])
                gstudent.className =
                  gstudent.className +
                  gstudent["classGroupName-2021"].toUpperCase();
            }
            gstudent.scores = null;
            this.lessonObject.ref
              .collection("studentList")
              .doc(gstudent.id)
              .onSnapshot((doc) => {
                if (doc.exists) gstudent.scores = doc.data().scores;
                else {
                  gstudent.scores = [];
                  if (this.semesterNumber > 0) {
                    for (var k = 1; k <= this.semesterNumber; k++) {
                      gstudent.scores.push({ finalScore: 0, semester: k });
                    }
                  }
                }
              });

            this.studentsOfGroup.push(gstudent);
          });
        });
    }

    this.bodyNames = ["firstName"];
  },
  mounted() {
    this.updatee();
  },
};
</script>
 <style>
</style>

 