<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="border-radius-xl card-shadow">
            <div class="px-4 py-4 mt-2">
              <IrzTable :teacherId="userData.ref.id" :selectedTeacher="userData" :zSchool="userData.school"> </IrzTable>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
import IrzTable from "@/views/Pages/Irz/neww/IrzTable.vue";
const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: {
    IrzTable,
  },
  // props: {
  //   path: {
  //     type: String,
  //     required: true,
  //   },
  // },
  computed: {
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
  },
  data() {
    return {
      lessons: null,
      path: null,
      tab: null,
      tabClassGroups: null,
      tabTexts: ["Сэдвүүд", "Даалгавар", "Дүн"],
      headerNames: null,
    };
  },
  methods: {},
  created() {
    if (this.yyschool) {
      this.path = this.yyschool + "lessons";
    } else {
      this.path = "assignmentsdatabase";
    }
    if (this.userData.role == "superadmin") {
      this.headerNames = [
        {
          text: "ID",
          align: "end",
          sortable: false,
          value: "index",
        },
        {
          text: "Xичээлийн нэр",
          value: "name",
        },
        // {
        //   text: "Xичээлийн Ref",
        //   value: "ref",
        //   sortable: true,
        // },
        {
          text: "Xичээлийн төрөл",
          value: "categoryName",
        },
        {
          text: "Багшийн нэр",
          value: "teacherName",
        },
        {
          text: "Төрөл",
          value: "lessonTypeName",
        },
        {
          text: "Ангийн тоо",
          value: "classes",
        },
        {
          text: "Үйлдэл",
          value: "actions",
        },
      ];
    }
  },
  mounted() {},
};
</script>

 