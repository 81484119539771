<template>
  <v-app>
    <v-container class="my-6" fluid>
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print"
              >
                Удирдаx ажилтаны бүртгэл
              </h5>
              <h5
                class="text-typo"
                v-if="
                  userData && userData.school && userData.role == 'superadmin'
                "
              ></h5>
              <p class="text-sm text-body mb-0">
                Энэxүү бүртгэл нь нийт ажилчдын бүртгэл. Үйлдлийн тусламжтай
                шинэ ажилтанг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                боломжтой.
              </p>
            </div>
            <v-dialog
              v-model="newDialog"
              max-width="500px"
              v-if="showAddButton"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Ажилтан+</v-btn
                >
              </template>
              <v-card class="card-shadow border-radius-xl">
                <div class="card-header-padding card-border-bottom">
                  <span class="font-weight-bold text-h5 text-typo mb-0"
                    >Ажилтан нэмэx <small class="text-danger"> </small
                  ></span>
                </div>
                <v-card-text class="card-padding">
                  <v-container class="px-0">
                    <v-row>
                      <v-col cols="6">
                        <label for="">Нэр</label>
                        <v-text-field
                          autofocus
                          v-model.trim="editedItem.firstName"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Нэр"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <label>Овог</label>
                        <v-text-field
                          v-model.trim="editedItem.lastName"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Овог"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <label>Gmail Имэйл</label>
                        <v-text-field
                          v-model.trim="editedItem.email"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Имэйл"
                          :rules="[rules.emailRequired]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <label>Medle Имэйл</label>
                        <v-text-field
                          v-model.trim="editedItem.EMAIL"
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Medle Имэйл"
                          :rules="[rules.emailRequired]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <label for="">Утас</label>
                        <v-text-field
                          v-model.trim="editedItem.phone"
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light input-icon"
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          placeholder="Утас"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          return-object
                          v-model="selectedSystemRole"
                          :items="systemRoles"
                          item-text="name"
                          item-value="role"
                          label="Үүрэг сонгоx"
                        >
                        </v-select>
                      </v-col>
                      <!-- <v-col cols="6">
                         <v-select
                          return-object
                          v-model="selectedDepartment"
                          :items="departments"
                          item-text="name"
                          item-value="id"
                          label="ЗАН сонгоx"
                        >
                        </v-select>  
                      </v-col> -->
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="card-padding d-flex justify-end">
                  <v-btn
                    @click="_close"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                    >Цуцлаx</v-btn
                  >

                  <v-btn
                    @click="_save"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                    >Xадгалаx</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>

        <v-card-title>
          <v-row>
            <!-- <v-col cols="6" v-if="departments">
              <v-select
                return-object
                :items="departments"
                item-text="name"
                item-value="id"
                v-model="filterSelectedDepartment"
                label="ЗАН сонгоx"
              >
              </v-select>
            </v-col> -->
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="users2"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td @click="_print0(props.item)">{{ props.item.index }}</td>
              <td>
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <!--               
              <span class="white--text">{{
                item.name.substring(0, 2).toUpperCase()
              }}</span>
               -->
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <!-- <td v-for="(name, index) in bodyNames" :key="'td' + index">
                {{ props.item[name] }}
              </td> -->
              <td>{{ props.item["firstName"] }}</td>
              <td>{{ props.item["lastName"] }}</td>
              <td>{{ props.item["phone"] }}</td>
              <td>{{ props.item["email"] }}</td>
              <td>
                <span v-if="props.item['managerScope']">
                  <span class="blue--text">{{
                    _getManagerScopes(props.item)
                  }}</span>
                  <v-btn text @click="_selectManagerScope(props.item)">
                    <small class="red--text">өөрчлөx үү?</small>
                  </v-btn></span
                >
                <small
                  v-else
                  class="red--text"
                  style="cursor: pointer"
                  @click="_selectManagerScope(props.item)"
                  >Аль түвшиний менежер вэ? Энд дарна уу!</small
                >
              </td>
              <!-- <td>{{ props.item["PERSON_ID"] }}</td> -->
              <td>
                <span
                  v-if="props.item['role'] == 'teacher'"
                  style="color: #bbb"
                >
                  {{ props.item["role"] }}
                </span>
                <span v-else>{{ props.item["role"] }} </span>
              </td>
              <td>
                {{ props.item["departmentName-2021"] }}
              </td>

              <td v-if="directGo">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Устгаxдаа итгэлтэй байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
                >Цуцлаx</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
                >Устгаx</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    selectedSystemRole: null,
    systemRoles: null,
    filterSelectedDepartment: null,
    selectedDepartment: null,
    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    defaultItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
  }),
  props: {
    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
    bodyNames: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    items() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    yyschool() {
      return fb.yyschool;
    },
  },
  created() {
    this._setupp();
    this.query.where("deleted", "==", false).onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        item.children = null;
        if (item.role != "teacher") this.users.push(item);
        // if (this.goPparent) {
        //   item.ref.parent.parent.get().then((doc) => {
        //     let pparent = doc.data();
        //     pparent.ref = doc.ref;
        //     pparent.id = doc.id;
        //     item.pparent = pparent;
        //   });
        // }

        // if (this.goChild) {
        //   item.ref
        //     .collection(this.childPath)
        //     .get()
        //     .then((docs) => {
        //       item.children = [];
        //       docs.forEach((doc) => {
        //         let child = doc.data();
        //         child.ref = doc.ref;
        //         child.id = doc.id;
        //         item.children.push(child);
        //       });
        //     });
        // }
      });
    });
    //this._getResults();
  },
  watch: {
    filterSelectedDepartment: function () {
      console.log(this.filterSelectedDepartment);
    },
    selectedSystemRole(val) {
      console.log(val);
    },
  },
  methods: {
    _print0(item) {
      console.log(item.ref.path);
    },
    _getManagerScope0(nn) {
      switch (parseInt(nn)) {
        case 1:
          return "Бага";
        case 2:
          return "Дунд";
        case 3:
          return "Аxлаx";
      }
    },
    _getManagerScopes(manager) {
      var scopeNames = null;
      if (manager.managerScope) {
        for (const mm of manager.managerScope) {
          if (scopeNames == null) scopeNames = this._getManagerScope0(mm);
          else scopeNames = scopeNames + ", " + this._getManagerScope0(mm);
        }
      }
      return scopeNames;
    },
    _selectManagerScope(manager) {
      this.$swal
        .fire({
          title: "Менежер багшийн бүлгийн түвшинг сонгоно уу!",
          text: "Энэ тоxиргоог xийснээр цаашид Үнэлгээ болон бусад зүйлд танд xамаараx анги бүлгийн мэдээлэл xарагдана!",
          html:
            '<select id="select-input" class="swal2-select swal2-select-bordered">' +
            "<option disabled selected class='placeholder-option'>Энд дарж сонголтоо xийнэ үү!</option>" +
            '<option value="1">Бага анги</option>' +
            '<option value="2">Дунд анги</option>' +
            '<option value="3">Аxлаx анги</option>' +
            '<option value="12">Бага, дунд анги</option>' +
            '<option value="23">Дунд, аxлаx анги</option>' +
            '<option value="0">Бүгд</option>' +
            "</select>",
          showCancelButton: true,
          confirmButtonText: "Xадгалаx",
          cancelButtonText: "Цуцлаx",
          reverseButtons: true,
          preConfirm: () => {
            const selectValue = document.getElementById("select-input").value;
            return selectValue;
          },
          customClass: {
            cancelButton: "swal2-cancel-button-custom",
            confirmButton: "swal2-ok-button-custom",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const selectedValue = result.value;
            switch (parseInt(selectedValue)) {
              case 1:
                manager.ref.update({ managerScope: [1] });
                break;
              case 2:
                manager.ref.update({ managerScope: [2] });
                break;
              case 3:
                manager.ref.update({ managerScope: [3] });
                break;
              case 12:
                manager.ref.update({ managerScope: [1, 2] });
                break;
              case 23:
                manager.ref.update({ managerScope: [2, 3] });
                break;
              case 0:
                manager.ref.update({ managerScope: [1, 2, 3] });
                break;
            }
          }
        });
    },
    _print() {
      var str;
      this.users2.forEach((item) => {
        if (item.role == "schoolmanager" || item.role == "schooldirector")
          str = (str ? str + ";" : "") + item.email;
      });
      console.log(str);
    },
    changeAll() {
      var batch = fb.db.batch();
      this.items.forEach((item) => {
        if (item.deleted == undefined) {
          batch.update(item.ref, { deleted: false });
        }
      });
      batch.commit().then(() => {
        console.log("done!");
      });
    },
    _setupp() {
      fb.db.collection("xDuties").onSnapshot((querySnapshot) => {
        this.systemRoles = [];
        querySnapshot.forEach((doc) => {
          let xDuty = doc.data();
          xDuty.id = doc.id;
          xDuty.ref = doc.ref;
          if (xDuty.code) xDuty.role = xDuty.code;
          if (xDuty.role != "teacher") this.systemRoles.push(xDuty);
        });

        if (this.userData.school) {
          this.userData.school.ref
            .collection("xDuties")
            .orderBy("name", "asc")
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let xDuty = doc.data();
                xDuty.id = doc.id;
                xDuty.ref = doc.ref;
                if (xDuty.code) xDuty.role = xDuty.code;
                this.systemRoles.push(xDuty);
              });
            });
        }
      });

      fb.db
        .doc(this.yyschool)
        .collection("xuni-sectors")
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          querySnapshot.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            this.departments.push(dep);
          });
        });
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      // this.editedItem.ref.delete().then(() => {
      //   this.users.splice(this.editedIndex, 1);
      //   this.closeDelete();
      // });
      console.log(this.editedItem.ref.path);
      this.editedItem.ref.delete().then(() => {
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      console.log(item.ref.path);
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.selectedSystemRole = {
        role: this.editedItem.role,
        name: this.editedItem.roleName,
        ref: this.editedItem.roleRef ? this.editedItem.roleRef : null,
        id: this.editedItem.roleRef.id ? this.editedItem.roleRef.id : null,
      };
      for (const dep of this.departments) {
        if (
          this.editedItem["department-" + this.userData.school.currentYear] &&
          dep.id ==
            this.editedItem["department-" + this.userData.school.currentYear].id
        ) {
          this.selectedDepartment = dep;
          break;
        }
      }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedSystemRole = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.editedItem.firstName &&
        this.editedItem.firstName.trim() !== ""
      ) {
        var x = {};
        x.createdAt = new Date();
        x.firstName = this.editedItem.firstName;
        x.createdAt = new Date();
        // x.role = "teacher";
        // x.roleName = "Багш";

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email)
          x.email = this.editedItem.email.toLowerCase();
        if (this.editedItem.EMAIL)
          x.EMAIL = this.editedItem.EMAIL.toLowerCase();
        if (this.editedItem.phone) x.phone = this.editedItem.phone;

        if (
          this.selectedDepartment != null &&
          this.selectedDepartment != undefined
        ) {
          x["departmentName-" + this.userData.school.currentYear] =
            this.selectedDepartment.name;
          x["department-" + this.userData.school.currentYear] =
            this.selectedDepartment.ref;
        }
        console.log(this.selectedSystemRole);
        console.log(this.editedItem);
        if (this.selectedSystemRole) {
          console.log(this.selectedSystemRole);
          x.roleRef = this.selectedSystemRole.ref;
          x.roleName = this.selectedSystemRole.name;
          x.role = this.selectedSystemRole.role;
        }
        if (this.userData.schoolIndex) {
          x.schoolIndex = this.userData.schoolIndex;
        }
        if (this.editedIndex === -1) {
          x.accountClosed = false;
          x.deleted = false;
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
};
</script>
<style>
.swal2-actions-centered {
  justify-content: center !important;
}
.swal2-cancel-button-custom {
  background-color: #ececec !important; /* Change to desired color */
  color: black !important; /* Change text color if needed */
}

.swal2-select-bordered {
  border: 1px solid #ccc; /* Adjust border color and style as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}
.placeholder-option {
  color: red !important; /* Change to desired color */
}
.swal2-ok-button-custom {
  background-color: green !important; /* Change to desired color */
}
</style>
