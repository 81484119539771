<template>
  <v-app>
    <v-container class="my-6 mx-auto scoreTables">
      <span id="autotime"></span>
      <v-card>
        <div
          class="card-header-padding"
          style="padding-top: 2rem; padding-bottom: 0rem"
        >
          <v-row>
            <v-col>
              <v-select
                v-if="currenSelectedLesson"
                return-object
                v-model="currenSelectedLesson"
                :items="lessons"
                item-text="name2"
                item-value="id"
                label="Xичээл сонгоx"
              >
              </v-select>
            </v-col>
            <v-col>
              <!-- <v-select
                return-object
                v-model="selectedMonth"
                :items="months"
                item-text="name"
                item-value="id"
                label="Сар сонгоx"
              >
              </v-select> -->

              <v-row justify="end">
                <small
                  @click="selectedMonth = month"
                  small
                  style="
                    cursor: pointer;
                    background: #dadada;
                    color: #000 !important;
                  "
                  class="
                    border-radius-sm
                    text-xxs
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                  "
                  :class="
                    selectedMonth == month
                      ? 'bg-gradient-warning white--text'
                      : ''
                  "
                  :style="selectedMonth == month ? 'color: white' : ''"
                  elevation="0"
                  :ripple="false"
                  v-for="(month, mindex) in months"
                  :key="'month-' + mindex"
                  >{{ month }}</small
                >
              </v-row>
            </v-col>
          </v-row>
        </div>
        <template v-if="currenSelectedLesson && selectedMonth">
          <v-tabs v-model="tabClassGroups" class="overflow-hidden px-6">
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              class="py-0"
              v-for="(classGroup, tabIndex) in currenSelectedLesson.classGroups"
              :key="'tabClassGroups' + tabIndex"
            >
              {{ classGroup.departmentName }}{{ classGroup.classGroupName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabClassGroups" class="mt-4">
            <v-tab-item
              v-for="(classGroup, tabIndex) in currenSelectedLesson.classGroups"
              :key="'tabItemClassGroups' + tabIndex"
            >
              <!-- <StudentsByClassGroupTable v-if="students[classGroup.classGroupRef.path]"
          :students="students[classGroup.classGroupRef.path]"
        ></StudentsByClassGroupTable> -->

              <XTableHomeWorksByStudents
                v-if="
                  students != null && students[classGroup.classGroupRef.path]
                "
                :selectedMonth="selectedMonth"
                :lesson="currenSelectedLesson"
                :students="students[classGroup.classGroupRef.path]"
              ></XTableHomeWorksByStudents>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <!-- <StudentsByClassGroup
          v-if="students"
          :lesson="lesson"
          :students="students"
        ></StudentsByClassGroup> -->
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import XTableHomeWorksByStudents from "./Widgets/XTableHomeWorksByStudents";
// import StudentsByClassGroup from "./Widgets/StudentsByClassGroup"
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    XTableHomeWorksByStudents,
    //StudentsByClassGroup
  },
  data: () => ({
    months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],
    currenSelectedLesson: null,
    tabClassGroups: 0,
    selectedMonth: null,
    query: null,
    bodyNames: null,
    students: null,
    lessons: null,
    collectionName: "homeworks",
    docName: "homework",
  }),
  props: {
    lessonObject: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
  },
  watch: {
    selectedMonth() {},
    currenSelectedLesson(val) {
      this.currenSelectedLesson = val;
      this.students = [];
      if (
        this.currenSelectedLesson != null &&
        this.currenSelectedLesson != undefined &&
        this.currenSelectedLesson.classGroups != null &&
        this.currenSelectedLesson.classGroups != undefined
      ) {
        this.currenSelectedLesson.classGroups.forEach((cgroup) => {
          fb.db
            .collection(this.yyschool + "students")
            .where("classGroup-2021", "==", cgroup.classGroupRef)
            .get()
            .then((docs) => {
              this.students[cgroup.classGroupRef.path] = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.id = doc.id;
                student.ref = doc.ref;
                student.classGroupRef = cgroup.classGroupRef;
                student.attendances = null;
                student.ref
                  .collection(this.collectionName)
                  .doc(this.currenSelectedLesson.id)
                  .collection(this.docName)
                  .onSnapshot((docs) => {
                    student.attendances = [];
                    if (!docs.empty) {
                      docs.forEach((doc) => {
                        if (doc.exists) {
                          // console.log(doc.data()); alga bolgoldoo
                          let x = doc.data();
                          x.ref = doc.ref;
                          x.id = doc.id;
                          student.attendances.push(x);
                        }
                      });
                    }
                  });
                this.students[cgroup.classGroupRef.path].push(student);
              });
            });
        });
      }
    },
  },
  created() {
    if (this.lessonObject != null)
      this.currenSelectedLesson = this.lessonObject;
    this.selectedMonth = new Date().getMonth() + 1;
    this._setupp();
    this.bodyNames = ["firstName"];
  },
  mounted() {
    //this.startTime();
    // fb.db
    //   .doc(this.path)
    //   .get()
    //   .then((doc) => {
    //     let lesson = doc.data();
    //     lesson.id = doc.id;
    //     lesson.ref = doc.ref;
    //     this.currenSelectedLesson = lesson;
    //     this.students = [];
    //     if (lesson.classGroups !== undefined && lesson.classGroups != null) {
    //       lesson.classGroups.forEach((cgroup) => {
    //         fb.db
    //           .collection("/schools/dfsdfsdf/students")
    //           .where("classGroup-2021", "==", cgroup.classGroupRef)
    //           .get()
    //           .then((docs) => {
    //             this.students[cgroup.classGroupRef.path] = [];
    //             docs.forEach((doc) => {
    //               let student = doc.data();
    //               student.id = doc.id;
    //               student.ref = doc.ref;
    //               student.classGroupRef = cgroup.classGroupRef;
    //               this.students[cgroup.classGroupRef.path].push(student);
    //             });
    //           });
    //       });
    //     }
    //   });
  },
  methods: {
    startTime() {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      document.getElementById("autotime").innerHTML = h + ":" + m + ":" + s;
      setTimeout(this.startTime, 1000);
    },

    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },
    async _setupp() {
      var query = fb.db.collection(this.yyschool + "lessons");

      query.onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.name2 =
            lesson.categoryName +
            " - " +
            (lesson.lessonType == 0 ? "Заавал" : "Сонгон");

          if (this.currenSelectedLesson == null) {
            this.currenSelectedLesson = lesson;
            this.students = [];
            if (
              lesson.classGroups !== undefined &&
              lesson.classGroups != null
            ) {
              lesson.classGroups.forEach((cgroup) => {
                fb.db
                  .collection(this.yyschool + "students")
                  .where("classGroup-2021", "==", cgroup.classGroupRef)
                  .orderBy("firstName", "asc")
                  .get()
                  .then((docs) => {
                    this.students[cgroup.classGroupRef.path] = [];
                    docs.forEach((doc) => {
                      let student = doc.data();
                      student.id = doc.id;
                      student.ref = doc.ref;
                      student.classGroupRef = cgroup.classGroupRef;
                      student.attendances = null;
                      student.ref
                        .collection("homeworks")
                        .doc(lesson.id)
                        .collection("homework")
                        .onSnapshot((docs) => {
                          student.attendances = [];
                          if (!docs.empty) {
                            docs.forEach((doc) => {
                              if (doc.exists) {
                                // console.log(doc.data()); alga bolgoldoo
                                let x = doc.data();
                                x.ref = doc.ref;
                                x.id = doc.id;
                                console.log(x);
                                student.attendances.push(x);
                              }
                            });
                          }
                        });
                      this.students[cgroup.classGroupRef.path].push(student);
                    });
                  });
              });
            }
          }

          this.lessons.push(lesson);
        });
      });
    },
  },
};
</script>
 <style>
</style>

 