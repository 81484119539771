<template>
  <v-app>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="my-0 scoreTables" style="padding: 0px !important">
      <v-card>
        <h1>{{ lesson.ref.path }}</h1>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Даалгаврын дүн
              </h5>
            </div>
          </div>
        </div>
        <div class="card-header-padding">
          <v-data-table
            :headers="headers2"
            :items="users2"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    v-for="(h, hIndex) in headers"
                    :key="hIndex"
                  >
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td class="text-center" style="width: 2%">
                  {{ props.item.index }}
                </td>
                <td
                  v-for="(name, index) in bodyNames"
                  :key="'td' + index"
                  class="pl-2"
                >
                  {{ props.item[name] }}
                </td>
                <td
                  class="text-end"
                  v-for="(homework, homeworkIndex) in homeworks"
                  :key="'homework' + homeworkIndex"
                >
                  <input
                    :id="props.item.id + '-' + homeworkIndex"
                    v-if="props.item.scores[homework.id] != undefined"
                    :placeholder="props.item.id"
                    type="number"
                    v-model.number="props.item.scores[homework.id].score"
                    @change="scoreChanged(props.item, homework, homeworkIndex)"
                    style="width: 60px"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    actionstatus: null,
    snackbar: false,
    statuses: [
      { text: "Тасалсан", value: -3 },
      { text: "Өвчтэй", value: -2 },
      { text: "Чөлөөтэй", value: -1 },
      { text: "Тодорxойгүй", value: 0 },
      { text: "Ирсэн", value: 1 },
    ],
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    users: null,
    endOfDay: 30,
    collectionName: "homeworks",
    docName: "homework",
    homeworks: null,
  }),
  props: {
    students: {
      type: Array,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    selectedMonth: {
      type: Number,
      required: true,
    },
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },

    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.students != null) {
        var counter = 0;
        for (var item of this.students) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-9.jpeg");
          list.push(item);
        }
      }
      return list;
    },
  },
  created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "2%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
        width: "30%",
      },
    ];

    this.path = this.yyschool + "students";

    this.bodyNames = ["firstName","score","turnedInAt"];
  },
  mounted() {
    this.setupp();
  },
  methods: {
    async setupp() {
      var homeworksSnap = await this.lesson.ref.collection("homeworks").onSnapshot();

      if (homeworksSnap && !homeworksSnap.empty) {
        this.homeworks = [];

        for (const doc of homeworksSnap.docs) {
          let topic = doc.data();
          topic.id = doc.id;
          topic.ref = doc.ref;
          this.headerNames.push({
            text: topic.title,
            align: "center",
            sortable: true,
          });
          this.homeworks.push(topic);
        }
      }

      var counter = 0;
      if (this.students != null && this.students != undefined) {
        for (const student of this.students) {
          counter++;
          student.index = counter;
          student.scores = [];
          for (const homework of this.homeworks) {
            var xx = await student.ref
              .collection("homeworks")
              .doc(this.lesson.id)
              .onSnapshot();

            if (xx.exists) {
              student.scores[homework.id] = {
                score: 33,
                homeworkRef: homework.ref,
              };
              //student.scores[homework.id] = { score: 33, homeworkRef: homework.ref };
            } else {
              student.scores[homework.id] = {
                score: -1,
                homeworkRef: homework.ref,
              };
            }
          }
          // this.lesson.ref
          //   .collection("studentList")
          //   .doc(student.id)
          //   .onSnapshot((doc) => {
          //     if (doc.exists) student.scores = doc.data().scores;
          //   });
        }
      }
    },
    async scoreChanged(student, homework, homeworkIndex) {
      console.log(student.ref.path);
      student.ref
        .collection("homeworks")
        .doc(this.lesson.id)
        .set(
          {
            scores: fb.firestore.FieldValue.arrayUnion({
              score: student.scores[homework.id].score,
              homeworkId: homework.id,
              homeworkRef: homework.ref,
            }),
          },
          { merge: true }
        )
        .then(() => {
          this.actionstatus =
            student.firstName + " дүн амжилттай xадгалагдлаа.";
          this.snackbar = true;
          document.getElementById(student.id + "-" + homeworkIndex).blur();
        });
    },

    _getStatus(student, day) {
      if (student.attendances != null && student.attendances != undefined) {
        for (const attend of student.attendances) {
          if (attend.day == day) {
            return attend.status;
          }
        }
      }
      return 1;
    },
    _setStatus(student, day, status) {
      console.log(student.ref.path);
      console.log(day, status.value, status.text);

      if (status.value != 1) {
        var found = false;
        student.ref
          .collection(this.collectionName)
          .doc(this.lesson.id)
          .collection(this.docName)
          .onSnapshot((docs) => {
            docs.forEach((doc) => {
              if (doc.data().day == day) {
                doc.ref.set(
                  { status: status.value, text: status.text },
                  { merge: true }
                );
                found = true;
              }
            });
          });
        if (found == false) {
          student.ref
            .collection(this.collectionName)
            .doc(this.lesson.id)
            .collection(this.docName)
            .doc()
            .set(
              {
                day: day,
                status: status.value,
                text: status.text,
                createdAt: new Date(),
              },
              { merge: true }
            );
        }
      } else {
        student.ref
          .collection(this.collectionName)
          .doc(this.lesson.id)
          .collection(this.docName)
          .onSnapshot((docs) => {
            docs.forEach((doc) => {
              if (doc.data().day == day) {
                // student.ref.collection(this.collectionName)
                console.log(doc);
                fb.db.doc(doc.ref.path).delete();
              }
            });
          });
      }
    },
  },
};
</script>
 <style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>