<template>
  <v-container class="mx-0 px-2" fluid>
    <v-tabs v-model="selectedTab">
      <v-tab :class="{ 'active-tab': selectedTab === 0 }">
        {{ $attrs.lesson.classGroups[0].classGroupName }} - Үнэлгээ нэгтгэл
      </v-tab>
      <v-tab :class="{ 'active-tab': selectedTab === 1 }">
        Үнэлгээний үзүүлэлт</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background-color: white">
      <v-tab-item style="background-color: white" class="mt-0">
        <Unelgee1Overview
          v-if="filterStudentsByClassGroup && examInfos"
          :students="$attrs.students"
          :lesson="$attrs.lesson"
          :examInfos="examInfos"
          :allScores="allScores"
          v-bind="$attrs"
          :semesterNumber="$attrs.semesterNumber"
        >
        </Unelgee1Overview>
      </v-tab-item>
      <v-tab-item style="background-color: white" class="mt-4">
        <Unelgee1Exams
          v-if="examInfos"
          :students="$attrs.students"
          :lesson="$attrs.lesson"
          :semesterNumber="$attrs.semesterNumber"
          :examInfos="examInfos"
          :allScores="allScores"
          v-bind="$attrs"
        >
        </Unelgee1Exams>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import Unelgee1Overview from "./Unelgee1Overview.vue";
import Unelgee1Exams from "./Unelgee1Exams.vue";
import { sync } from "vuex-pathify";
// const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      selectedTab: 0,
      filterStudentsByClassGroup: null,
      tabClassGroups: null,
      examInfos: null,
      allScores: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  components: {
    Unelgee1Overview,
    Unelgee1Exams,
  },
  methods: {
    getUserRef() {
      return this.userData.ref;
    },
 
  },
  mounted() {
    if (
      this.$attrs.lesson.classGroups &&
      this.$attrs.lesson.classGroups.length > 0
    ) {
      this.filterStudentsByClassGroup =
        this.$attrs.lesson.classGroups[0].classGroupRef;
    } else this.filterStudentsByClassGroup = -1;

    // var pp = this.userData.school.ref.path+"/"+"courses-"+this.userData.school.currentYear
    //         + "/" + this.$attrs.lesson.courseInfo.COURSE_ID.toString()
    //         + "/" + "unelgee1-"+this.userData.school.currentYear
    //         console.log(pp)

    this.userData.school.ref
      .collection("courses-" + this.userData.school.currentYear)
      .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
      .collection("unelgee1-" + this.userData.school.currentYear)
      .where("createdByRef", "==", this.getUserRef())
      .orderBy("examDate", "asc")
      .onSnapshot((querySnapshot) => {
        this.examInfos = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.index = counter;
          if(item.newVersion&&item.mixedForLessons){
            if (!item.deleted) this.examInfos.push(item);
          }else{
            if (item["changes"]) {
            var xx = item["changes"].find(
              (cc) =>
                cc.classGroup &&
                cc.lessonId == this.$attrs.lesson.id &&
                this.filterStudentsByClassGroup.id ==
                  cc.classGroup.classGroupRef.id
            );
            if (xx) {
              // console.log(xx.examDate, xx, item);
              item.examConclusion = xx.examConclusion;
              item.examDate = xx.examDate;
              item.examDescription = xx.examDescription;

              item.lessonGroupId = xx["lessonGroup"]
                ? xx["lessonGroup"].id
                : null;
              item.lessonGroup = xx["lessonGroup"] ? xx["lessonGroup"] : null;
              item.classGroup = xx["classGroup"] ? xx["classGroup"] : null;
              item.lessonId = xx.lessonId;
              if (!item.deleted) this.examInfos.push(item);
            }
          } else {
            item.ref.update({
              changes: [
                {
                  lessonId: item.lessonId ? item.lessonId : null,
                  examConclusion: item.examConclusion
                    ? item.examConclusion
                    : null,
                  examDate: item.examDate ? item.examDate : null,
                  examDescription: item.examDescription
                    ? item.examDescription
                    : null,

                  lessonGroupId: item["lessonGroup"]
                    ? item["lessonGroup"].id
                    : null,
                  lessonGroup: item["lessonGroup"] ? item["lessonGroup"] : null,
                  classGroup:
                    this.$attrs.lesson.classGroups &&
                    this.$attrs.lesson.classGroups.length > 0
                      ? this.$attrs.lesson.classGroups[0]
                      : null,
                },
              ],
            });
          }
          }
        });
      });

    this.userData.school.ref
      .collection("courses-" + this.userData.school.currentYear)
      .doc(this.$attrs.lesson.courseInfo.COURSE_ID.toString())
      .collection("unelgee1-" + this.userData.school.currentYear + "-scores")
      // .where("STUDENT_GROUP_ID","in", [100003497414761])
      .onSnapshot((querySnapshot) => {
        this.allScores = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          this.allScores.push(item);
        });
      });
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>
