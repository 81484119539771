<template>
  <v-app>
    <v-container class="my-0 scoreTables" style="padding: 0px !important">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Xичээлийн ирц
              </h5>
              <p class="text-sm text-body mb-0">
                Сурагчдын ирцийг бүртгэx. Ирсэн: 1, Тодорxойгүй: 0, Чөлөөтэй:
                -1, Өвчтэй: -2, Тасалсан: -3
              </p>
            </div>
          </div>
        </div>
        <div class="card-header-padding">
          <v-data-table
            :headers="headers2"
            :items="users2"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    v-for="(h, hIndex) in headers"
                    :key="hIndex"
                  >
                    <span>{{ h.text }}</span>
                  </th>
                </tr>
              </thead>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td class="text-center">{{ props.item.index }}</td>
                <td
                  v-for="(name, index) in bodyNames"
                  :key="'td' + index"
                  class="pl-2"
                >
                  {{ props.item[name] }}
                </td>

                <v-menu
                  bottom
                  offset-y
                  v-for="day in endOfDay"
                  :key="'td' + day"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      v-bind="attrs"
                      v-on="on"
                      style="cursor: pointer; background: red"
                      :style="
                        _getStatus(props.item, day) == 1
                          ? 'background:#B0ED63;'
                          : _getStatus(props.item, day) == 0 //Тодорxойгүй
                          ? 'background:#bbb'
                          : _getStatus(props.item, day) == -1 //Чөлөөтэй
                          ? 'background:#87B3FC'
                          : _getStatus(props.item, day) == -2 //Өвчтэй
                          ? 'background:yellow'
                          : _getStatus(props.item, day) == -3
                          ? 'background:red; color:white'
                          : ''
                      "
                      class="text-center"
                    >
                      {{ _getStatus(props.item, day) }}
                    </td>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(status, index) in statuses"
                      :key="index"
                      style="cursor: pointer"
                    >
                      <v-list-item-title
                        @click="_setStatus(props.item, day, status)"
                        >{{ status.text }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    statuses: [
      { text: "Тасалсан", value: -3 },
      { text: "Өвчтэй", value: -2 },
      { text: "Чөлөөтэй", value: -1 },
      { text: "Тодорxойгүй", value: 0 },
      { text: "Ирсэн", value: 1 },
    ],
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    users: null,
    endOfDay: 30,
    collectionName: "lessonactivities",
    docName: "lessonactivity",
  }),
  props: {
    students: {
      type: Array,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    selectedMonth: {
      type: Number,
      required: true,
    },
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.students != null) {
        var counter = 0;
        for (var item of this.students) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-9.jpeg");
          list.push(item);
        }
      }
      return list;
    },
  },
  created() {
    this.path = this.yyschool + "students";
    // this.query = fb.db
    //   .collection(this.path)
    //   .orderBy("firstName", "asc")
    //   .where("classGroup-2021", "==", this.userData["myClassRef-2021"])
    //   .onSnapshot((querySnapshot) => {
    //     this.users = [];
    //     querySnapshot.forEach((doc) => {
    //       let student = doc.data();
    //       student.id = doc.id;
    //       student.ref = doc.ref;
    //       student.attendances = null;
    //       console.log(this.collectionName)
    //       student.ref.collection(this.collectionName).onSnapshot((docs) => {
    //         student.attendances = [];
    //         docs.forEach((doc) => {
    //           let attend = doc.data();
    //           attend.id = doc.id;
    //           attend.ref = doc.ref;
    //           //student.attendances.push(attend);
    //         });
    //       });
    //       this.users.push(student);
    //     });
    //   });

    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "2%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
        width: "30%",
      },
    ];
    if (
      this.selectedMonth == 1 ||
      this.selectedMonth == 3 ||
      this.selectedMonth == 5 ||
      this.selectedMonth == 7 ||
      this.selectedMonth == 8 ||
      this.selectedMonth == 10 ||
      this.selectedMonth == 12
    ) {
      console.log(this.endOfDay);
      this.endOfDay = 31;
    } else if (this.selectedMonth == 2) {
      this.endOfDay = 28;
    }
    for (var i = 0; i < this.endOfDay; i++) {
      this.headerNames.push({
        text: i + 1,
        align: "center",
        value: "val-" + (i + 1),
        sortable: true,
      });
    }
    this.bodyNames = ["firstName"];
  },
  methods: {
    _getStatus(student, day) {
      if (student.attendances != null && student.attendances != undefined) {
        for (const attend of student.attendances) {
          if (attend.day == day) {
            return attend.status;
          }
        }
      }
      return 1;
    },
    _setStatus(student, day, status) {
      console.log(student.ref.path);
      console.log(day, status.value, status.text);

      if (status.value != 1) {
        var found = false;
        student.ref
          .collection(this.collectionName)
          .doc(this.lesson.id)
          .collection(this.docName)
          .onSnapshot((docs) => {
            docs.forEach((doc) => {
              if (doc.data().day == day) {
                doc.ref.set(
                  { status: status.value, text: status.text },
                  { merge: true }
                );
                found = true;
              }
            });
          });
        if (found == false) {
          student.ref
            .collection(this.collectionName)
            .doc(this.lesson.id)
            .collection(this.docName)
            .doc()
            .set(
              {
                day: day,
                status: status.value,
                text: status.text,
                createdAt: new Date(),
              },
              { merge: true }
            );
        }
      } else {
        student.ref
          .collection(this.collectionName)
          .doc(this.lesson.id)
          .collection(this.docName)
          .onSnapshot((docs) => {
            docs.forEach((doc) => {
              if (doc.data().day == day) {
                // student.ref.collection(this.collectionName)
                console.log(doc);
                fb.db.doc(doc.ref.path).delete();
              }
            });
          });
      }
    },
  },
};
</script>
 <style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>