var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-6 mx-auto scoreTables",staticStyle:{"max-width":"100% !important"}},[_c('v-card',[_c('div',{staticClass:"card-header-padding"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v("Цагийн анализ")]),_c('p',{staticClass:"text-sm text-body mb-0"},[_vm._v(" Ээлжийн цаг бүр дээр ангийн сурагчдын ирцийн талаар мэдээлэл. ")])]),_c('div',{staticClass:"card-header-padding",staticStyle:{"padding-bottom":"0rem"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"mt-n3",attrs:{"items":_vm.days,"return-object":"","label":"Өдөр сонгоx"},model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}})],1),_c('v-col',[_c('v-row',{attrs:{"justify":"end"}},_vm._l((_vm.months),function(month,mindex){return _c('small',{key:'month-' + mindex,staticClass:"border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1",class:_vm.selectedMonth == month
                    ? 'bg-gradient-warning white--text'
                    : '',staticStyle:{"cursor":"pointer","background":"#dadada","color":"#000 !important"},style:(_vm.selectedMonth == month ? 'color: white' : ''),attrs:{"small":"","elevation":"0","ripple":false},on:{"click":function($event){_vm.selectedMonth = month}}},[_vm._v(_vm._s(month))])}),0)],1)],1)],1),(_vm.xstudents)?_c('v-data-table',{staticClass:"mt-10 mx-16",attrs:{"headers":_vm.headers2,"items":_vm.xstudents,"hide-default-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header",fn:function({ props: {} }){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-right":"0px !important","border-left":"1px solid #bbb"}},[_vm._v(" No. ")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v("Сурагчдын нэрс")]),_vm._l((_vm.lessons),function(day,xlessonIndex){return _c('th',{key:'day-' + xlessonIndex,staticClass:"text-center blue--text",style:(_vm.selectedDay == day ? 'color: red; font-weight: bold' : ''),attrs:{"title":_vm.selectedDay == day ? 'Өнөөдрийн он сар' : ''}},[_vm._v(" "+_vm._s(day.name2)+" цаг ")])})],2)])]}},{key:"item",fn:function(props){return [_c('tr',{attrs:{"id":props.item.id}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.index))]),_c('td',{staticClass:"pl-2",staticStyle:{"width":"20%"}},[_c('span',[_vm._v(_vm._s(props.item.firstName))]),(props.item.lastName)?_c('span',[_vm._v(_vm._s(". " + props.item.lastName[0]))]):_vm._e()]),_vm._l((_vm.lessons),function(xlesson,xlessonIndex){return [_c('td',{key:'xlessonData-' + xlessonIndex,style:(_vm._getStatus(xlesson, props.item) == 'Ирсэн'
                    ? 'background:#f1ffe0; color: black !important'
                    : _vm._getStatus(xlesson, props.item) == 'Тодорxойгүй' //Тодорxойгүй
                    ? 'background:#bbb'
                    : _vm._getStatus(xlesson, props.item) == 'Xоцорсон' //Xоцорсон
                    ? 'background:#87B3FC'
                    : _vm._getStatus(xlesson, props.item) == 'Чөлөөтэй' //Чөлөөтэй
                    ? 'background:yellow; color:black!important'
                    : _vm._getStatus(xlesson, props.item) == 'Өвчтэй' //Өвчтэй
                    ? 'background:#FFA500; color:white'
                    : _vm._getStatus(xlesson, props.item) == 'Тасалсан' //Тасалсан
                    ? 'background:red; color:white'
                    : '')},[(_vm._getStatus(xlesson, props.item) != 'Ирсэн')?_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm._getStatus(xlesson, props.item)))]):_c('span',{staticClass:"pl-2"},[_vm._v("-")])])]})],2)]}}],null,false,946858310)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }