<template>
  <v-row class="fill-height">
    <v-container class="my-6 mx-auto">
      <v-card class="py-10">
        <div class="card-header-padding">
          <v-row class="mb-4">
            <v-col cols="auto">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Xичээлийн xуваарь
                </h5>
                <p class="text-sm text-body mb-0">
                  Xүснэгтийн нүдэнд дарж xуваариа оруулаx боломжтой.
                </p>
              </div>
            </v-col>

            <v-col
              lg="4"
              md="6"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
            >
              <div class="d-flex align-center">
                <p class="mb-0 text-body text-xs ms-auto" v-if="addd == true">
                  Xуваарь нэмэx
                </p>
                <p class="mb-0 text-body text-xs ms-auto" v-else>Дэлгэрэнгүй xараx</p>
                <v-switch
                  :ripple="false"
                  class="mt-0 pt-0 ms-3 switch"
                  v-model="addd"
                  hide-details
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding">
          <v-simple-table class="bborder">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    No
                  </th>
                  <th>Name</th>
                  <th v-for="dep in 7" :key="dep" class="text-center">
                    {{ dep }}
                    <p>sdfsadfs</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(lesson, lindex) in 7" :key="lesson">
                  <td
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    {{ lindex + 1 }}.
                  </td>
                  <td>{{ lesson }}</td>
                  <td
                    class="text-center borderCell"
                    v-for="dep in 7"
                    :key="dep"
                    @click="_clickCell(dep)"
                  >
                    {{new Date().getDate()}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </v-container>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    addd:false
  }),
  mounted() {},
  methods: {
    _clickCell(xx){
      console.log(xx)
    }
  },
};
</script>
<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: #F7F78C;
}
</style>