<template>
  <v-app>
    <v-container
      class="my-6 mx-auto scoreTables"
      style="max-width: 100% !important"
    >
      <span id="autotime"></span>
      <v-card>
        <div class="card-header-padding">
          <v-row justify="end">
            <small
              @click="selectedMonth = month"
              small
              style="
                cursor: pointer;
                background: #dadada;
                color: #000 !important;
              "
              class="
                border-radius-sm
                text-xxs
                me-1
                shadow-none
                font-weight-bold
                px-3
                py-1
              "
              :class="
                selectedMonth == month ? 'bg-gradient-warning white--text' : ''
              "
              :style="selectedMonth == month ? 'color: white' : ''"
              elevation="0"
              :ripple="false"
              v-for="(month, mindex) in months"
              :key="'month-' + mindex"
              >{{ month }}</small
            >
          </v-row>
          <div class="d-flex flex-row">
            <div class="px-4">
              <template v-if="lessonObject.classGroups">
                <span
                  @click="selectedClassGroup = classGroup"
                  style="cursor: pointer"
                  class="mr-4"
                  v-for="(classGroup, cgindex) in lessonObject.classGroups"
                  :key="cgindex"
                >
                  <span
                    v-if="
                      selectedClassGroup &&
                      selectedClassGroup.classGroupRef &&
                      selectedClassGroup.classGroupRef.path ==
                        classGroup.classGroupRef.path
                    "
                    class="font-weight-bold pb-2 px-4"
                    style="color: #56ca28 !important; border-bottom: 3px solid"
                    >{{ classGroup.departmentName + classGroup.classGroupName }}
                    <span v-if="students3">({{ students3.length }})</span>
                  </span>
                  <span v-else class="pb-2 px-4"
                    >{{ classGroup.departmentName + classGroup.classGroupName }}
                    <span v-if="students3">({{ students3.length }})</span>
                  </span>
                </span></template
              >
              <span
                v-if="this.lessonObject.isGroupBased == true"
                @click="selectedClassGroup = null"
              >
                <span
                  v-if="selectedClassGroup == null"
                  class="font-weight-bold pb-2 px-4"
                  style="
                    cursor: pointer;
                    color: #56ca28 !important;
                    border-bottom: 3px solid;
                  "
                  >Групп
                  <span v-if="studentsOfGroup"
                    >({{ studentsOfGroup.length }})</span
                  ></span
                >
                <span v-else style="cursor: pointer" class="pb-2 px-4"
                  >Групп
                  <span v-if="studentsOfGroup"
                    >({{ studentsOfGroup.length }})</span
                  >
                </span>
              </span>
            </div>
          </div>
        </div>
        <template>
          <AttendanceLessonByStudents
            v-if="students3 != null && students3"
            :selectedMonth="selectedMonth"
            :lesson="currentSelectedLesson"
            :students="students3"
          ></AttendanceLessonByStudents>
        </template>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AttendanceLessonByStudents from "./AttendanceLessonByStudents";
// import StudentsByClassGroup from "./Widgets/StudentsByClassGroup"
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    AttendanceLessonByStudents,
    //StudentsByClassGroup
  },
  data: () => ({
    selectedClassGroup: null,
    kkk: 0,
    xselectedClassGroup: null,
    months: [1, 2, 3, 4, 5, 6,  9, 10, 11, 12],
    currentSelectedLesson: null,
    tabClassGroups: 0,
    selectedMonth: null,
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    studentsOfGroup: null, //
    students2: null, // students from classgroup
    lessons: null,
  }),
  props: {
    lessonObject: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },

    headers2() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < 31; i++) {
        list.push({
          text: i + 1,
          align: "center",
          value: "val-" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
    body2() {
      var list = [];
      var row;
      for (var k = 0; k < 300; k++) {
        row = {
          index: k + 1,
          firstName: "User-" + (k + 1),
        };
        for (var i = 1; i < 31; i++) {
          var xx = "val-" + (i + 1).toString();
          var tmp = {};
          tmp[xx] = i + 1;

          console.log(tmp);
          row[xx] = i + 1;
        }
        list.push(row);
      }

      return list;
    },
    students3() {
      var list = [];
      if (this.selectedClassGroup && this.students2) {
        for (var xstudent of this.students2) {
          console.log(xstudent);
          if (
            this.selectedClassGroup.classGroupRef &&
            this.selectedClassGroup.classGroupRef.path ==
              xstudent.classGroupRef.path &&
            xstudent.classGroupRef
          )
            list.push(xstudent);
        }
      } else {
        if (this.selectedClassGroup == null && this.studentsOfGroup) {
          for (var student of this.studentsOfGroup) {
            list.push(student);
          }
        }
      }
      return list;
    },
  },
  watch: {
    tabClassGroups(val) {
      // var x = this.currentSelectedLesson.classGroups[val].classGroupRef;
      console.log(val);
      // if (this.students[x.path] == undefined) {
      //   this.findStudents(x);
      // }
    },
    selectedMonth() {},
  },
  methods: {
    findStudents(classGroupRef) {
      fb.db
        .collection(this.zzschool + "/students")
        .where("classGroup-2021", "==", classGroupRef)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            student.classGroupRef = classGroupRef;
            student.attendances = null;
            student.ref
              .collection("attendances")
              .doc(this.currentSelectedLesson.id)
              .collection("attendance")
              .onSnapshot((docs) => {
                student.attendances = [];
                if (!docs.empty) {
                  docs.forEach((doc) => {
                    if (doc.exists) {
                      // console.log(doc.data()); alga bolgoldoo
                      let x = doc.data();
                      x.ref = doc.ref;
                      x.id = doc.id;
                      student.attendances.push(x);
                    }
                  });
                }
              });
            this.students2.push(student);
          });
        });
    },
    startTime() {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      document.getElementById("autotime").innerHTML = h + ":" + m + ":" + s;
      setTimeout(this.startTime, 1000);
    },

    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },
    async _setupp() {
      var query = fb.db
        .collection(this.zzschool + "/lessons")
        .where("deleted", "==", false)
        .where("year", "==", 2021);

      if (this.userData.role == "teacher") {
        query = query.where("teacherRef", "==", this.userData.ref);
      }
      query = query.orderBy("createdAt", "desc");

      query.onSnapshot((docs) => {
        this.lessons = [];
        docs.forEach((doc) => {
          let lesson = doc.data();
          lesson.id = doc.id;
          lesson.ref = doc.ref;
          lesson.name2 =
            lesson.categoryName +
            " - " +
            (lesson.lessonType == 1 ? "Заавал" : "Сонгон");

          this.lessons.push(lesson);
        });
      });
    },
  },
  created() {
    this.selectedMonth = new Date().getMonth() + 1;
    if (this.lessonObject != null)
      this.currentSelectedLesson = this.lessonObject;

    if (
      this.lessonObject.classGroups &&
      this.lessonObject.classGroups.length > 0
    ) {
      this.students2 = [];
      this.selectedClassGroup = this.lessonObject.classGroups[0];
      this.lessonObject.classGroups.forEach((doc) => {
        this.findStudents(doc.classGroupRef);
      });
    }

    if (this.lessonObject.isGroupBased == true) {
      this.lessonObject.ref
        .collection("lesson-students")
        .orderBy("firstName", "asc")
        .onSnapshot((docs) => {
          this.studentsOfGroup = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let gstudent = doc.data();
            gstudent.index = counter;
            gstudent.id = doc.id;
            gstudent.ref = doc.ref;
            gstudent.selectiveAdded = true;
            if (gstudent["departmentName-2021"]) {
              gstudent.className = gstudent["departmentName-2021"];
              if (gstudent["classGroupName-2021"])
                gstudent.className =
                  gstudent.className +
                  gstudent["classGroupName-2021"].toUpperCase();
            }
            gstudent.attendances = null;
            gstudent.ref
              .collection("attendances")
              .doc(this.currentSelectedLesson.id)
              .collection("attendance")
              .onSnapshot((docs) => {
                gstudent.attendances = [];
                if (!docs.empty) {
                  docs.forEach((doc) => {
                    if (doc.exists) {
                      // console.log(doc.data()); alga bolgoldoo
                      let x = doc.data();
                      x.ref = doc.ref;
                      x.id = doc.id;
                      gstudent.attendances.push(x);
                    }
                  });
                }
              });
            this.studentsOfGroup.push(gstudent);
          });
        });
    }
    this._setupp();
    this.headerNames = [
      {
        text: "xxx",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ];
    for (var x of Array(12).keys()) {
      x++;
      this.headerNames.push({
        text: x,
        align: "start",
        sortable: true,
        value: x,
      });
    }
    this.bodyNames = ["firstName"];
  },
};
</script>
 <style>
</style>

 