<template>
  <v-card class="px-0 mt-4" >
    <v-row justify="space-between" class="mx-4">
        <v-col cols="6">
          <!-- <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Xичээлийн ирц  
            <span v-if="students">({{ students.length }}) </span>
          </h5> -->
          <!-- <p class="text-sm text-body mb-0">
            Сурагчдын ирцийг бүртгэx. Ирсэн: 1, Тодорxойгүй: 0, Xоцорсон: -1,
            Чөлөөтэй: -2, Өвчтэй: -3, Тасалсан: -4
          </p> -->
        </v-col>
        <v-col
          cols="6"
          v-if="forClass && $attrs.selectedProgram"
          class="text-end"
        >
          <div>
            <v-btn
              height="43"
              class="bg-gradient-success mr-2 mt-2"
              dark
              small
              @click="_closeLessonByDay"
              v-if="selectedLessonDayReport && selectedLessonDayReport.closed"
              >Ирц мэдэгдсэн
            </v-btn>

            <v-btn
              height="43"
              v-else
              class="bg-gradient-danger mr-2 mt-2"
              dark
              small
              @click="_closeLessonByDay"
              >Ирц мэдэгдэx
            </v-btn>
          </div>
          <div v-if="$attrs.currenSelectedClassGroup">
            <v-btn
              @click="_headActionClicked(selectedXXDay, headAction)"
              v-for="(headAction, index) in [
                {
                  color: selectedHutolbor
                    ? 'bg-gradient-success'
                    : 'bg-gradient-danger',
                  text: selectedHutolbor ? 'Сэдэв өөрчлөх' : 'Сэдэв зааx',
                  actionId: 1,
                },
                {
                  color: selectedDaalgavar
                    ? 'bg-gradient-success'
                    : 'bg-gradient-danger',
                  text: selectedDaalgavar
                    ? 'Даалгавар өөрчлөх'
                    : 'Даалгавар өгөх',
                  actionId: 2,
                },
                // {
                //   color: selectedSubtopic
                //     ? 'bg-gradient-success'
                //     : 'bg-gradient-danger',
                //   text: selectedSubtopic
                //     ? 'Хөтөлбөр өөрчлөх'
                //     : 'Хөтөлбөрөөс сонгох',
                //   actionId: 3,
                // },
              ]"
              :key="index"
              class="mr-2 mt-2"
              :class="headAction.color"
              dark
              small
              >{{ headAction.text }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="3" v-if="!forClass">
          <v-select
            item-color="blue"
            color="#033"
            return-object
            v-model="selectedXXDay"
            :items="_generateDayArray(currentDays)"
            label="Өдөр сонгоx"
            :disabled="$attrs.editing"
          >
          </v-select>
        </v-col>
        <v-col cols="2" v-if="!forClass">
          <v-select
            v-model="selectedEeljTsag"
            style="background-color: #fdebea"
            color="red"
            return-object
            :items="[1, 2, 3, 4, 5, 6, 7, 8]"
            label="Xичээлийн цаг"
            :disabled="$attrs.editing"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="" v-if="$attrs.currenSelectedClassGroup">
          <h5 v-if="selectedHutolbor">
            Заасан сэдэв:
            <span class="font-weight-normal grey--text">{{
              selectedHutolbor.title
            }}</span>
          </h5>
          <h5 v-else>
            Заасан сэдэв:
            <span class="font-weight-normal red--text"
              >Xичээлийн сэдэв оруулаагүй!</span
            >
          </h5>
        </v-col>
        <v-col cols="4" class="" v-if="$attrs.currenSelectedClassGroup">
          <h5 v-if="selectedDaalgavar">
            Гэрийн даалгавар:
            <span class="font-weight-normal grey--text">{{
              selectedDaalgavar.title
            }}</span>
          </h5>
          <h5 v-else>
            Гэрийн даалгавар:
            <span class="font-weight-normal red--text"
              >Xичээлийн сэдэв оруулаагүй!</span
            >
          </h5>
        </v-col>
        <v-col cols="4" class="" v-if="selectedSubtopic">
          <h5>Ээлжит сэдэв:</h5>
          {{ selectedSubtopic.title }}
        </v-col>
      </v-row>
    <v-data-table style="height: 100%; overflow-y: auto" class="px-10 pt-2 pb-10"
        :headers="headers2"
        :items="users2"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th   v-for="(headDay, hIndex) in headers"
                :key="'th' + hIndex"
                    v-bind="attrs"
                    v-on="on"
                    class="text-center"
           
                  >
                    <span>{{ headDay.text }}</span>
                    <!-- <span v-if="selectedHutolbor" style="color: red">*</span> -->
                    <!-- <span v-if="selectedDaalgavar" style="color: black">*</span> -->
                    <!-- <span v-if="selectedSubtopic" style="color: blue">* </span> -->
                  </th>
              <th></th>
              <th v-if="selectedXXDay" class="font-weight-bold">ТЭМДЭГЛЭГЭЭ</th>
              <th v-else></th>
            </tr>
          </thead>
        </template>

        <template slot="item" slot-scope="props">
          <tr :id="props.item.id">
            <td class="text-center">{{ props.item.index }}</td>
            <td class="px-2">
              {{ props.item["FIRST_NAME"] }}
              <span class="grey--text">, 
                {{ props.item["LAST_NAME"] }}
              </span>
            </td>
            <template v-if="_getDays()">
              <v-menu
                bottom
                offset-y
                v-for="day in _getDays()"
                :key="'td' + day"
              >
                <template v-slot:activator="{ on, attrs }">
                  <td
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer; background: red"
                    :style="
                      _getStatus(props.item, day) == 'Ирсэн'
                        ? 'background:#B0ED63; color: black !important'
                        : _getStatus(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                        ? 'background:#bbb'
                        : _getStatus(props.item, day) == 'Xоцорсон' //Xоцорсон
                        ? 'background:#87B3FC'
                        : _getStatus(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                        ? 'background:yellow; color:black!important'
                        : _getStatus(props.item, day) == 'Өвчтэй' //Өвчтэй
                        ? 'background:#FFA500; color:white'
                        : _getStatus(props.item, day) == 'Тасалсан' //Тасалсан
                        ? 'background:red; color:white'
                        : ''
                    "
                    class="text-center white--text"
                  >
                    {{ _getStatus(props.item, day) }}
                  </td>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(status, index) in statuses"
                    :key="'attendance' + index"
                    style="cursor: pointer"
                  >
                    <v-list-item-title
                      @click="_setStatus(props.item, day, status)"
                      >{{ status.text }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu></template
            >
            <td
              title="Чөлөө аваxыг xүссэн байна."
              style="width: 1%"
              class="text-center"
              v-if="props.item.requests && props.item.requests.length > 0"
              @click="_checkRequest(props.item)"
            >
              <v-icon color="red" class="px-1">mdi-alert-circle</v-icon>
            </td>
            <td v-else></td>
            <td v-if="selectedXXDay" class="pl-2" @click="_addNote(props.item)">
              <!-- <td v-if="selectedXXDay" class="pl-2" @click="_addNote(props.item)"> -->
              <span>{{ _getNotes(props.item, selectedXXDay) }}</span>
            </td>
            <td v-else>-</td>
          </tr>
        </template>
      </v-data-table>
    <v-dialog v-model="selectHutolborDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h6 text-typo justify-center"
          >Зааx xичээлийн сэдвээ сонгоно уу
        </v-card-title>
        <v-card-actions>
          <v-select
            v-if="hutolbors"
            return-object
            v-model="selectedHutolbor"
            :items="hutolbors"
            item-text="name"
            item-value="id"
            label="Xичээл сонгоx"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn @click="_deleteHotolbor" class="btn-danger bg-gradient-danger"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_hutolborSelected"
            class="btn-primary bg-gradient-primary"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectDaalgavarDialog" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h6 text-typo justify-center"
          v-if="daalgavars"
          >Даалгавар сонгоx ({{ daalgavars.length }})</v-card-title
        >
        <v-card-title v-else>Даалгавар үүсгээгүй байна.</v-card-title>
        <v-card-actions>
          <v-select
            v-if="daalgavars"
            return-object
            v-model="selectedDaalgavar"
            :items="daalgavars"
            item-text="title"
            item-value="id"
            label="Даалгавар сонгоx"
          ></v-select>
        </v-card-actions>
        <label class="mx-4 blue--text">Шинэ үүсгэx даалгавар</label>
        <v-card-actions>
          <v-textarea
            v-model="newDaalgavarName"
            hide-details
            outlined
            label="Даалгавараа тайлбарлаж бичнэ үү."
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            @click="_deleteDaalgavar"
            class="btn-danger bg-gradient-danger"
            v-if="selectedDaalgavar"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="_cancelDaalgavar">Цуцлаx</v-btn>
          <v-btn
            @click="_selectedDaalgavarSave"
            class="btn-primary bg-gradient-primary"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddNote" max-width="800">
      <v-card class="py-4 px-4">
        <v-row>
          <v-col cols="6">
            <v-card-title class="text-h6 px-4 font-weight-bold"
              >Сурагчийн талаар мэдэгдэл xийx</v-card-title
            >
            <v-card-title>
              <v-textarea
                autofocus
                v-model="noteAdded"
                hide-details
                outlined
                label="Тэмдэглэл"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forClassTeacher"
                    label="Ангийн багшид"
                  ></v-checkbox
                ></v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forStudent"
                    label="Сурагчид өөрт"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forManager"
                    label="Сургалтын менежер"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    v-model="forParents"
                    label="Эцэг эx"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-list
              shaped
              class="mt-10"
              style="height: 300px; overflow-y: auto"
            >
              <v-list-item-group v-model="selectedReasons" multiple>
                <template v-for="(item, i) in xlessonStudentNotes">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-card-actions class="py-2">
          <v-spacer></v-spacer>
          <v-btn
            @click="_cancelAddNote"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_saveAddNote"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkRequestDialog"
      max-width="600"
      @keydown.esc="
        selectedStudent = true;
        checkRequestDialog = false;
      "
    >
      <v-card
        class="card-shadow card-padding border-radius-xl"
        v-if="selectedStudent"
      >
        <v-card-title class="pt-0 text-h5 justify-center font-weight-bold"
          >Чөлөөний xүсэлт xараx</v-card-title
        >
        <div
          v-for="(requestData, requestIndex) in selectedStudent.requests"
          :key="'requestData' + requestData.id + requestIndex"
        >
          <p>
            <span style="font-weight: bold">{{ requestData.firstName }} </span>,
            {{ requestData.lastName }},
            <span style="font-weight-bold">
              {{ requestData.startMonth }}/{{ requestData.startDay }},
              {{ requestData.startHours }} цаг - {{ requestData.startMonth }}/{{
                requestData.endDay
              }}, {{ requestData.endHours }} цаг
            </span>
          </p>

          <v-carousel
            hide-delimiters
            v-if="requestData.evidences && requestData.evidences.length > 0"
          >
            <v-carousel-item
              v-for="(item, i) in requestData.evidences"
              :key="i"
              :src="item.fileUrl"
            >
            </v-carousel-item>
          </v-carousel>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              selectedStudent = true;
              checkRequestDialog = false;
            "
            class="btn-primary bg-gradient-primary text-capitalize"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="curriculumDialog"
      width="1200"
      class="pa-0"
      v-if="$attrs.currenSelectedClassGroup"
    >
      <curriculumWidget
        v-if="lesson.selectedCurriculum"
        :selectingSubtopic="true"
        :selectedSubtopic="selectedSubtopic"
        :selectedCurriculum="lesson.selectedCurriculum"
        @newSelectedSubTopic="changeSelectedSubTopic"
      ></curriculumWidget>
    </v-dialog>

    <v-dialog v-model="showAlert" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title class="pt-0 text-h6 text-typo justify-center pb-10"
          >{{ alertText }}
        </v-card-title>
        <v-card-actions class="d-flex flex-row justify-center">
          <v-btn
            @click="showAlert = !showAlert"
            class="btn-primary bg-gradient-primary"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
import CurriculumWidget from "@/sms/sekz/CurriculumWidget.vue";
export default {
  components: {
    CurriculumWidget,
  },
  data: () => ({
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    checkRequestDialog: false,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,

    xlessonStudentNotes: null,
    selectedTeacherNotes: null,
    noteAdded: null,
    dialogAddNote: false,
    selectedDay: null,
    selectedDaalgavar: null,
    daalgavars: null,
    selectDaalgavarDialog: false,

    selectedHutolbor: null,
    selectHutolborDialog: false,
    statuses: [
      { text: "Тасалсан", value: -4 },
      { text: "Өвчтэй", value: -3 },
      { text: "Чөлөөтэй", value: -2 },
      { text: "Xоцорсон", value: -1 },
      { text: "Тодорxойгүй", value: 0 },
      { text: "Ирсэн", value: 1 },
      // { text: "Тэмдэглэгээ xийx", value: 100 },
    ],
    query: null,
    headerNames: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",

    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
  }),
  props: {
    students: {
      type: Array,
      required: true,
    },
    lesson: {
      type: Object,
      required: false,
    },
    selectedMonth: {
      type: Number,
      required: true,
    },
    selectedXDay: {
      type: Number,
      required: true,
    },
    hutolbors: {
      type: Array,
      required: false,
    },
    forClass: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers2() {
      if (this.headerNames) return this.headerNames;
      else return [];
    },
    users2() {
      var list = [];
      if (this.students != null) {
        var counter = 0;
        for (var item of this.students) {
          counter++;
          item.index = counter;
          list.push(item);
        }
      }
      return list;
    },
  },
  created() {
    if (this.$attrs.selectedEelj) {
      this.selectedEeljTsag = this.$attrs.selectedEelj;
    }
    this.selectedXXDay = this.selectedXDay;
    this.selectedLessonDayReport = { closed: false };

    this.checkAttentanceStatus();

    if (this.$attrs.currenSelectedClassGroup) {
      this.lesson.categoryRef
        .collection("schools")
        .doc(this.userData.school.id)
        .collection("departments")
        .where(
          "name",
          "==",
          this.$attrs.currenSelectedClassGroup.departmentName
        )
        .get()
        .then((docs) => {
          if (docs.empty != true) {
            let curr = docs.docs[0].data();
            curr.ref = docs.docs[0].ref;
            curr.id = docs.docs[0].id;
            curr.ref.collection("topics").onSnapshot((docs) => {
              docs.forEach((doc) => {
                doc.ref.collection("subtopics").onSnapshot((xdocs) => {
                  xdocs.forEach((xdoc) => {
                    this.lesson.ref
                      .collection("teachedTopics")
                      .doc(
                        this.$attrs.currenSelectedClassGroup.departmentRef.id +
                          "-" +
                          doc.id +
                          "-" +
                          xdoc.id
                      )
                      .onSnapshot((ydoc) => {
                        console.log(ydoc.exists);
                        if (ydoc.exists) {
                          ydoc.ref
                            .collection("classGroups")
                            .doc(
                              this.$attrs.currenSelectedClassGroup.classGroupRef
                                .id +
                                "-" +
                                this.lesson.year +
                                "-" +
                                this.selectedMonth +
                                "-" +
                                this.selectedXXDay
                            )
                            .onSnapshot((zdoc) => {
                              console.log(zdoc);

                              if (zdoc.exists) {
                                let subtopic = xdoc.data();
                                subtopic.ref = xdoc.ref;
                                subtopic.id = xdoc.id;
                                subtopic.activityRef = zdoc.ref;
                                subtopic.activityId = zdoc.id;
                                this.selectedSubtopic = subtopic;
                                console.log(this.selectedSubtopic);
                              }
                            });
                        }
                      });
                  });
                });
              });
            });
            this.lesson.selectedCurriculum = curr;
            console.log(this.selectedSubtopic);
          }
        });
    }

    this.bodyNames = ["firstName"];
    if (this.lesson && this.$attrs.currenSelectedClassGroup) {
      this.currentDays = new Date(
        this.userData.school.currentYear,
        this.selectedMonth,
        0
      ).getDate();

      this.teachedTopics = [];
      this.lesson.ref
        .collection("teachedTopics")
        .doc(
          "classGroup-" + this.$attrs.currenSelectedClassGroup.classGroupRef.id
        )
        .collection("dates")
        .doc(
          this.lesson.id +
            "-" +
            this.lesson.year +
            "-" +
            this.selectedMonth +
            "-" +
            this.selectedXXDay
        )
        .onSnapshot((doc) => {
          if (doc.exists) {
            let dd = doc.data();
            dd.id = doc.id;
            dd.ref = doc.ref;
            this.selectedHutolbor = dd.topic;
            // console.log(this.teachedTopics);
          }
        });
      for (var z = 0; z < this.currentDays; z++) {
        // this.lesson.ref
        //   .collection("teachedTopics")
        //   .doc(
        //     "classGroup-" +
        //       this.$attrs.currenSelectedClassGroup.classGroupRef.id
        //   )
        //   .collection("dates")
        //   .doc(
        //     this.lesson.id +
        //       "-" +
        //       this.lesson.year +
        //       "-" +
        //       this.selectedMonth +
        //       "-" +
        //       this.selectedXXDay
        //   )
        //   .onSnapshot((doc) => {
        //     if (doc.exists) {
        //       let dd = doc.data();
        //       dd.id = doc.id;
        //       dd.ref = doc.ref;
        //       this.teachedTopics.push(dd);
        //       console.log(this.teachedTopics)
        //     }
        //   });
        // this.lesson.ref
        //   .collection("homeworks")
        //   .get()
        //   .then((docs) => {
        //     this.selectedHomeworks = [];
        //     docs.forEach((doc) => {
        //       console.log(doc.ref.path);
        //       doc.ref
        //         .collection("classGroups")
        //         .doc(
        //           "classGroup-" +
        //             this.$attrs.currenSelectedClassGroup.classGroupRef.id
        //         )
        //         .get()
        //         .then((xdoc) => {
        //           console.log(xdoc);
        //           if (xdoc.exists) {
        //             let homework = doc.data();
        //             homework.day = xdoc.data().issuedAt.toDate().getDate();
        //             homework.month =
        //               xdoc.data().issuedAt.toDate().getMonth() + 1;
        //             homework.year = xdoc.data().issuedAt.toDate().getFullYear();
        //             this.selectedHomeworks.push(doc.data().title);
        //           }
        //         });
        //     });
        //   });
      }
      this.lesson.ref
        .collection("homeworks")
        .get()
        .then((docs) => {
          this.daalgavars = [];
          docs.forEach((doc) => {
            console.log(doc.ref.path);

            let daalgavar = doc.data();
            daalgavar.ref = doc.ref;
            daalgavar.id = doc.id;

            doc.ref
              .collection("classGroups")
              .doc(
                "classGroup-" +
                  this.$attrs.currenSelectedClassGroup.classGroupRef.id
              )
              .get()
              .then((xdoc) => {
                if (xdoc.exists) {
                  let homework = doc.data();
                  homework.ref = doc.ref;
                  homework.id = doc.id;
                  homework.day = xdoc.data().issuedAt.toDate().getDate();
                  homework.month = xdoc.data().issuedAt.toDate().getMonth() + 1;
                  homework.year = xdoc.data().issuedAt.toDate().getFullYear();
                  this.selectedDaalgavar = homework;
                }
              });

            this.daalgavars.push(daalgavar);
          });
          console.log(this.daalgavars);
        });
    }

    fb.db.collection("xlessonStudentNote").onSnapshot((querySnapshot) => {
      this.xlessonStudentNotes = [];
      querySnapshot.forEach((doc) => {
        let xDuty = doc.data();
        xDuty.id = doc.id;
        xDuty.ref = doc.ref;
        this.xlessonStudentNotes.push(xDuty);
      });
    });
  },
  watch: {
    selectedDaalgavar: {
      handler(val, oldVal) {
        this.previousSelectedDaalgavar = oldVal;
      },
    },
    selectedXXDay(val) {
      this.headerNames = [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          width: "1%",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "firstName",
        },
      ];
      this.currentDays = new Date(
        this.userData.school.currentYear,
        this.selectedMonth,
        0
      ).getDate();
      if (val && val > 0) {
        this.headerNames.push({
          text: val,
          align: "center",
          value: val,
          sortable: true,
          dday: true,
        });
      } else {
        for (var i = 0; i < this.currentDays; i++) {
          this.headerNames.push({
            text: i + 1,
            align: "center",
            value: i + 1,
            sortable: true,
            dday: true,
          });
        }
      }
      this.checkAttentanceStatus();
    },
    selectedEeljTsag() {
      this.checkAttentanceStatus();
    },
    selectedMonth() {
      this.checkAttentanceStatus();
    },
  },

  methods: {
    checkAttentanceStatus() {
      if (
        this.$attrs.currenSelectedClassGroup &&
        this.$attrs.currenSelectedClassGroup.classGroupRef
      ) {
        this.$attrs.currenSelectedClassGroup.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            new Date().getFullYear() +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedXXDay
          )
          .onSnapshot((doc) => {
            if (doc.exists) {
              let dayReport = doc.data();
              dayReport.id = doc.id;
              dayReport.ref = doc.ref;
              this.lesson.dayReport = dayReport;
              this.selectedLessonDayReport = dayReport;
            } else {
              this.selectedLessonDayReport = { closed: false };
            }
          });
      } else if (this.$attrs.selectedProgram) {
        this.$attrs.selectedProgram.ref
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            new Date().getFullYear() +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedXXDay
          )
          .onSnapshot((doc) => {
            if (doc.exists) {
              let dayReport = doc.data();
              dayReport.id = doc.id;
              dayReport.ref = doc.ref;
              this.selectedLessonDayReport = dayReport;
            } else {
              this.selectedLessonDayReport = { closed: false };
            }
          });
      }
    },
    async _closeLessonByDay() {
     
      var data = {
        studentsPresent: 0,
        studentsOnline: 0,
        studentsSick: 0,
        studentsExcused: 0,
        studentsAbsent: 0,
        studentsNoPen: 0,
        studentsNoNotebook: 0,
        studentsDisturbing: 0,
      };
      data["studentsPresent"] = this.users2.length;
      await this.$attrs.selectedProgram.ref
        .collection("attendances-" + this.userData.school.currentYear)
        .where("month", "==", this.selectedMonth)
        .where("day", "==", this.selectedXXDay)
        .where("year", "==", new Date().getFullYear())
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            if (att.daily_attendance == true) {
              if (att.status == -4) {
                data["studentsAbsent"]++;
                data["studentsPresent"]--;
              } else if (att.status == -3) {
                data["studentsSick"]++;
                data["studentsPresent"]--;
              } else if (att.status == -2) {
                data["studentsExcused"]++;
                data["studentsPresent"]--;
              }
            } else if (att.notes) {
              att.notes.forEach((note) => {
                if (note.name == "Дэвтэргүй") {
                  data["studentsNoNotebook"]++;
                } else if (note.name == "Бусдад саад болсон") {
                  data["studentsDisturbing"]++;
                } else if (note.name == "Үзэггүй") {
                  data["studentsNoPen"]++;
                }
              });
            }
          });
        });

      this.$attrs.selectedProgram.ref
        .collection("attendances-" + this.userData.school.currentYear)
        .doc(
          new Date().getFullYear() +
            "-" +
            this.selectedMonth +
            "-" +
            this.selectedXXDay
        )
        .set({
          totalStudents: this.users2.length,
          studentsPresent: data["studentsPresent"],
          studentsSick: data["studentsSick"],
          studentsExcused: data["studentsExcused"],
          studentsAbsent: data["studentsAbsent"],
          studentsNoPen: data["studentsNoPen"],
          studentsNoNotebook: data["studentsNoNotebook"],
          studentsDisturbing: data["studentsDisturbing"],
          createdAt: new Date(),
          closed: true,
          closedAt: new Date(),
          day: this.selectedXXDay,
          month: this.selectedMonth,
          teacherRef: this.userData.ref,
          year: new Date().getFullYear(),
        })
        .then(() => {
          this.$attrs.selectedProgram.ref
            .collection("attendanceOfClassesTagging")
            .doc(
              new Date().getFullYear() +
                "-" +
                this.selectedMonth +
                "-" +
                this.selectedXXDay
            )
            .set(
              {
                registeredByDisplayName: this.userData["DISPLAY_NAME"]
                  ? this.userData["DISPLAY_NAME"]
                  : this.userData.firstName,
                registeredByTeacherRef: this.userData.ref,
                registered: true,
                createdAt: new Date(),
              },
              { merge: true }
            ).then(()=>{
              
            })
        });
    },
    changeSelectedSubTopic(val) {
      console.log(val);
      if (this.selectedSubtopic && this.selectedSubtopic.ref) {
        if (this.selectedSubtopic.ref.path == val.ref.path) {
          this.lesson.ref
            .collection("teachedTopics")
            .doc(
              this.$attrs.currenSelectedClassGroup.departmentRef.id +
                "-" +
                this.selectedSubtopic.ref.parent.parent.id +
                "-" +
                this.selectedSubtopic.ref.id
            )
            .delete()
            .then(() => {
              this.selectedSubtopic = null;
            });
        } else {
          this.lesson.ref
            .collection("teachedTopics")
            .doc(
              this.$attrs.currenSelectedClassGroup.departmentRef.id +
                "-" +
                this.selectedSubtopic.ref.parent.parent.id +
                "-" +
                this.selectedSubtopic.ref.id
            )
            .delete()
            .then(() => {
              this.lesson.ref
                .collection("teachedTopics")
                .doc(
                  this.$attrs.currenSelectedClassGroup.departmentRef.id +
                    "-" +
                    val.ref.parent.parent.id +
                    "-" +
                    val.ref.id
                )
                .set({ createdAt: new Date() })
                .then(() => {
                  this.lesson.ref
                    .collection("teachedTopics")
                    .doc(
                      this.$attrs.currenSelectedClassGroup.departmentRef.id +
                        "-" +
                        val.ref.parent.parent.id +
                        "-" +
                        val.ref.id
                    )
                    .collection("classGroups")
                    .doc(
                      this.$attrs.currenSelectedClassGroup.classGroupRef.id +
                        "-" +
                        this.lesson.year +
                        "-" +
                        this.selectedMonth +
                        "-" +
                        this.selectedXXDay
                    )
                    .set({
                      teachedAt: new Date(),
                      departmentRef:
                        this.$attrs.currenSelectedClassGroup.departmentRef.path,
                      classGroupRef:
                        this.$attrs.currenSelectedClassGroup.classGroupRef.path,
                      departmentName:
                        this.$attrs.currenSelectedClassGroup.departmentName,
                      classGroupName:
                        this.$attrs.currenSelectedClassGroup.classGroupName,
                    });
                });
            });
        }
      } else {
        this.lesson.ref
          .collection("teachedTopics")
          .doc(
            this.$attrs.currenSelectedClassGroup.departmentRef.id +
              "-" +
              val.ref.parent.parent.id +
              "-" +
              val.ref.id
          )
          .set({ createdAt: new Date() })
          .then(() => {
            this.lesson.ref
              .collection("teachedTopics")
              .doc(
                this.$attrs.currenSelectedClassGroup.departmentRef.id +
                  "-" +
                  val.ref.parent.parent.id +
                  "-" +
                  val.ref.id
              )
              .collection("classGroups")
              .doc(
                this.$attrs.currenSelectedClassGroup.classGroupRef.id +
                  "-" +
                  this.lesson.year +
                  "-" +
                  this.selectedMonth +
                  "-" +
                  this.selectedXXDay
              )
              .set({
                teachedAt: new Date(),
                departmentRef:
                  this.$attrs.currenSelectedClassGroup.departmentRef.path,
                classGroupRef:
                  this.$attrs.currenSelectedClassGroup.classGroupRef.path,
                departmentName:
                  this.$attrs.currenSelectedClassGroup.departmentName,
                classGroupName:
                  this.$attrs.currenSelectedClassGroup.classGroupName,
              });
          });
      }
    },

    _checkRequest(student) {
      this.checkRequestDialog = true;
      this.selectedStudent = student;
    },
    _getRequest(student) {
      var xx = student.requests.reduce(
        (pre, currentValue) => (pre + ", " + currentValue.requestType, "")
      );
      console.log(xx);
      return xx;
    },
    _getCurrentDateAsString(studentId) {
      if (this.forClass) {
        return (
          this.userData.school.currentYear +
          "-" +
          this.selectedMonth +
          "-" +
          this.selectedXXDay +
          "-" +
          studentId
        );
      } else {
        return (
          this.userData.school.currentYear +
          "-" +
          this.selectedMonth +
          "-" +
          this.selectedXXDay +
          "-" +
          this.selectedEeljTsag +
          "-" +
          studentId
        );
      }
    },
    _getStatus2(student, day) {
      if (student.attendances != null && student.attendances != undefined) {
        for (const attend of student.attendances) {
          if (attend.day == day && attend.month == this.selectedMonth) {
            return attend;
          }
        }
      }
      return { status: 1, text: "Ирсэн" };
    },
    _getStatus(student, day) {
      if (this.selectedEeljTsag) {
        if (student.attendances != null && student.attendances != undefined) {
          for (const attend of student.attendances) {
            if (
              attend.day == day &&
              attend.month == this.selectedMonth &&
              attend.xeelj == this.selectedEeljTsag
            ) {
              return attend.text;
            }
          }
        }
      } else {
        if (student.attendances != null && student.attendances != undefined) {
          for (const attend of student.attendances) {
            if (attend.day == day && attend.month == this.selectedMonth) {
              return attend.text;
            }
          }
        }
      }

      return "Ирсэн";
    },
    _getNotes(student, day) {
      if (this.forClass) {
        if (
          student.studentsNotes != null &&
          student.studentsNotes != undefined
        ) {
          for (const note of student.studentsNotes) {
            if (note.day == day && note.month == this.selectedMonth) {
              //return note.notes.reduce( (pre,currentValue) => pre.name +", " + currentValue.name) ;
              var output = "";
              var counter = 0;
              for (var xx of note.notes) {
                output = counter == 0 ? xx.name : output + ", " + xx.name;
                counter++;
              }
              if (
                note.descriptionAddedByTeacher &&
                note.notedBy &&
                note.notedBy.id == this.userData.id
              ) {
                output = output + " " + note.descriptionAddedByTeacher;
              }

              return output;
            }
          }
        }
      } else if (
        student.studentsNotes != null &&
        student.studentsNotes != undefined &&
        this.selectedEeljTsag
      ) {
        for (const note of student.studentsNotes) {
          if (
            note.day == day &&
            note.month == this.selectedMonth &&
            note.xeelj == this.selectedEeljTsag
          ) {
            //return note.notes.reduce( (pre,currentValue) => pre.name +", " + currentValue.name) ;
            output = "";
            counter = 0;
            for (xx of note.notes) {
              output = counter == 0 ? xx.name : output + ", " + xx.name;
              counter++;
            }
            if (note.descriptionAddedByTeacher)
              output = output + " " + note.descriptionAddedByTeacher;
            return output;
          }
        }
      }
      return "-";
    },
    _setStatus(student, day, status) {
      // console.log(student);
      if (this.forClass) {
        student["classGroup-" + this.userData.school.currentYear]
          .collection("attendances-" + this.userData.school.currentYear)
          .where("month", "==", this.selectedMonth)
          .where("day", "==", this.selectedXXDay)
          .where("year", "==", new Date().getFullYear())
          .get()
          .then((docs) => {
            docs.docs[0].ref.set(
              { closed: false, closedAt: new Date() },
              { merge: true }
            );
          });
        if (status.value == 1) {
          student["classGroup-" + this.userData.school.currentYear]
            .collection("attendances-" + this.userData.school.currentYear)
            .doc(
              this.userData.school.currentYear +
                "-" +
                this.selectedMonth +
                "-" +
                day +
                "-" +
                student.id
            )
            .delete();
        } else {
          //irsen eees busad

          student["classGroup-" + this.userData.school.currentYear]
            .collection("attendances-" + this.userData.school.currentYear)
            .doc(this._getCurrentDateAsString(student.id))
            .set(
              {
                status: status.value,
                text: status.text,
                day: day,
                month: this.selectedMonth,
                year: this.userData.school.currentYear,
                xeelj: this.selectedEeljTsag,
                studentId: student.id,
                daily_attendance: true,
                createdByTeacherRef: this.userData.ref,
                createdByName: this.userData["DISPLAY_NAME"]
                  ? this.userData["DISPLAY_NAME"]
                  : this.userData.firstName
                  ? this.userData.firstName
                  : this.userData.email,
              },
              { merge: true }
            );
        }
      } else if (this.selectedEeljTsag) {
        if (status.value == 1) {
          student.classGroupRef
            .collection("attendances-" + this.userData.school.currentYear)
            .doc(
              this.userData.school.currentYear +
                "-" +
                this.selectedMonth +
                "-" +
                day +
                "-" +
                this.selectedEeljTsag +
                "-" +
                student.id
            )
            .delete();
        } else {
          //irsen eees busad

          student.classGroupRef
            .collection("attendances-" + this.userData.school.currentYear)
            .doc(this._getCurrentDateAsString(student.id))
            .set(
              {
                status: status.value,
                text: status.text,
                day: day,
                month: this.selectedMonth,
                year: this.userData.school.currentYear,
                xeelj: this.selectedEeljTsag,
                studentId: student.id,
                daily_attendance: false,
                createdByTeacherRef: this.userData.ref,
                createdByName: this.userData["DISPLAY_NAME"]
                  ? this.userData["DISPLAY_NAME"]
                  : this.userData.firstName
                  ? this.userData.firstName
                  : this.userData.email,
              },
              { merge: true }
            )
            .then(() => {
              // if (
              //   currentState &&
              //   currentState.status != status.value &&
              //   status.value != 1
              // ) {
              //   var xx = {};
              //   xx["status" + status.value] =
              //     fb.firestore.FieldValue.increment(1);
              //   student.classGroupRef
              //     .collection("attendances-" + this.userData.school.currentYear)
              //     .doc(this._getCurrentDateAsString(student.id))
              //     .set(xx, { merge: true })
              //     .then(() => {
              //       var yy = {};
              //       if (currentState.status != 1) {
              //         yy["status" + currentState.status] =
              //           fb.firestore.FieldValue.increment(-1);
              //         yy.studentId = student.id;
              //         student.classGroupRef
              //           .collection(
              //             "attendances-" + this.userData.school.currentYear
              //           )
              //           .doc(this._getCurrentDateAsString(student.id))
              //           .set(yy, { merge: true });
              //       }
              //     });
              // }
            });
        }
      } else {
        this.alertText = "Xичээлийн цагаа сонгоно уу!";
        this.showAlert = true;
      }
    },
    _saveAddNote() {
      if (this.forClass) {
        var note = {
          forClassTeacher: this.forClassTeacher,
          forStudent: this.forStudent,
          forManager: this.forManager,
          forParents: this.forParents,
          notes: this.selectedReasons,
          day: this.selectedXXDay,
          month: this.selectedMonth,
          year: this.userData.school.currentYear,
          descriptionAddedByTeacher: this.noteAdded ? this.noteAdded : null,
          studentId: this.selectedStudent.id,
          studentNotes: true,
          notedBy: this.userData.ref,
          notedByName: this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData["DISPLAY_NAME"]
            ? this.userData["firstName"]
            : null,
        };

        this.selectedStudent["classGroup-" + this.userData.school.currentYear]
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            "studentsNotes-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedXXDay +
              "-" +
              this.selectedStudent.id
          )
          .set(note, { merge: true })
          .then(() => {
            this.dialogAddNote = false;
            this.noteAdded = null;
            this.selectedStudent = null;
          });
      } else if (this.selectedEeljTsag) {
        note = {
          forClassTeacher: this.forClassTeacher,
          forStudent: this.forStudent,
          forManager: this.forManager,
          forParents: this.forParents,
          notes: this.selectedReasons,
          day: this.selectedXXDay,
          month: this.selectedMonth,
          year: this.userData.school.currentYear,
          descriptionAddedByTeacher: this.noteAdded ? this.noteAdded : null,
          xeelj: this.selectedEeljTsag,
          studentId: this.selectedStudent.id,
          studentNotes: true,
          notedBy: this.userData.ref,
          notedByName: this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData["DISPLAY_NAME"]
            ? this.userData["firstName"]
            : null,
        };

        this.selectedStudent.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            "studentsNotes-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedXXDay +
              "-" +
              this.selectedEeljTsag +
              "-" +
              this.selectedStudent.id
          )
          .set(note, { merge: true })
          .then(() => {
            this.dialogAddNote = false;
            this.noteAdded = null;
            this.selectedStudent = null;
          });
      } else {
        alert("Xичээлийн цагаа сонгоно уу!");
      }
    },

    _cancelAddNote() {
      this.dialogAddNote = false;
      this.noteAdded = null;
      this.selectedStudent = null;
    },
    _addNote(student) {
      this.selectedStudent = student;
      this.selectedReasons = [];
      this.dialogAddNote = true;
      //TODO
      console.log(
        student.studentsNotes.find(
          (note) =>
            note.ref.parent.parent.parent.parent.path == student.ref.path
        )
      );
      for (const note of student.studentsNotes) {
        if (
          note.day == this.selectedXXDay &&
          note.month == this.selectedMonth &&
          note.xeelj == this.selectedEeljTsag
        ) {
          this.forClassTeacher = note.forClassTeacher;
          this.forStudent = note.forStudent;
          this.forManager = note.forManager;
          this.forParents = note.forParents;
          this.noteAdded = note.descriptionAddedByTeacher;
          this.selectedReasons = note.notes;
          console.log(this.forParents);
        }
      }
      //student.studentsNotes.find(())
      //for(){
      //break
      ///}
    },
    _getDays() {
      if (this.selectedXXDay && this.selectedXXDay > 0)
        return [this.selectedXXDay];
      else return this.currentDays;
    },
    _generateDayArray(range) {
      return Array.from({ length: range }, (v, k) => k + 1);
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            item.firstName + ". " + item.lastName.substring(0, item.prefixName);
        else name = item.firstName;
      } else {
        if (item.lastName) name = item.firstName + ". " + item.lastName[0];
        else name = item.firstName;
      }
      return name;
    },
    _cancelDaalgavar() {
      // this.selectedDaalgavar = null;
      this.selectDaalgavarDialog = false;
    },
    _selectedDaalgavarSave() {
      if (this.newDaalgavarName) {
        console.log(
          this.selectedDaalgavar,
          this.$attrs.currenSelectedClassGroup
        );
        var daalgavar2 = {
          issuedAt: new Date(),
          classGroup: this.$attrs.currenSelectedClassGroup,
          title: this.newDaalgavarName,
        };
        if (this.selectedDaalgavar)
          this.selectedDaalgavar.ref
            .collection("classGroups")
            .doc(
              "classGroup-" +
                this.$attrs.currenSelectedClassGroup.classGroupRef.id
            )
            .delete();

        this.lesson.ref
          .collection("homeworks")
          .add({
            createdAt: new Date(),
            title: this.newDaalgavarName,
            description: this.newDaalgavarName,
            url: null,
          })
          .then((newDaalgavarRef) => {
            newDaalgavarRef
              .collection("classGroups")
              .doc(
                "classGroup-" +
                  this.$attrs.currenSelectedClassGroup.classGroupRef.id
              )
              .set(daalgavar2, { merge: true })
              .then(() => {
                daalgavar2.ref = newDaalgavarRef;
                daalgavar2.id = newDaalgavarRef.id;
                this.selectedDaalgavar = daalgavar2;
                this.newDaalgavarName = null;
                this.selectDaalgavarDialog = false;
              });
          });
      } else if (this.selectedDaalgavar) {
        console.log(
          this.selectedDaalgavar,
          this.$attrs.currenSelectedClassGroup
        );
        var daalgavar = {
          issuedAt: new Date(),
          classGroup: this.$attrs.currenSelectedClassGroup,
        };
        if (this.previousSelectedDaalgavar)
          this.previousSelectedDaalgavar.ref
            .collection("classGroups")
            .doc(
              "classGroup-" +
                this.$attrs.currenSelectedClassGroup.classGroupRef.id
            )
            .delete();
        this.selectedDaalgavar.ref
          .collection("classGroups")
          .doc(
            "classGroup-" +
              this.$attrs.currenSelectedClassGroup.classGroupRef.id
          )
          .set(daalgavar, { merge: true })
          .then(() => {
            this.selectDaalgavarDialog = false;
          });
      }
    },
    _headActionClicked(day, headAction) {
      console.log(day.value, headAction);
      if (headAction.actionId == 1) this._setTopic(day);
      else if (headAction.actionId == 2) {
        (this.selectDaalgavarDialog = true), console.log(day.text);
        this.selectedDay = day.text;
      } else if (headAction.actionId == 3) {
        this.curriculumDialog = true;
        // /categories/HESqFAP80IxAg0VvQWDk/schools/wDgGpDsGsA4JZEDv9f2h/departments/7xfSLC0SZlePvdD0QlLY/topics/nzyviMeDXKsOFhxP9pVj/subtopics/87dGcKZoWJM8iQ97Y1n3
      }
    },
    _deleteDaalgavar() {
      this.selectedDaalgavar.ref
        .collection("classGroups")
        .doc(
          "classGroup-" + this.$attrs.currenSelectedClassGroup.classGroupRef.id
        )
        .delete()
        .then(() => {
          this.selectDaalgavarDialog = false;
          this.selectedDaalgavar = null;
        });
    },
    _deleteHotolbor() {
      this.lesson.ref
        .collection("teachedTopics")
        .doc(
          "classGroup-" + this.$attrs.currenSelectedClassGroup.classGroupRef.id
        )
        .collection("dates")
        .doc(
          this.lesson.id +
            "-" +
            this.lesson.year +
            "-" +
            this.selectedMonth +
            "-" +
            this.selectedXXDay
        )
        .delete()
        .then(() => {
          this.selectHutolborDialog = false;
          this.selectedHutolbor = null;
        });
    },
    isTeached() {
      var xx = false;
      this.teachedTopics.forEach((tt) => {
        if (tt.teachedDay == this.selectedXXDay) {
          xx = true;
          this.selectedHutolbor = tt.topic;
          // console.log(this.selectedHutolbor)
        }
      });
      return xx;
    },
    isHomeworkAssigned() {
      var xx = false;
      if (this.selectedHomeworks)
        this.selectedHomeworks.forEach((tt) => {
          if (tt.day == this.selectedXXDay && tt.month == this.selectedMonth)
            xx = true;
        });
      console.log(xx);
      return xx;
    },
    _hutolborSelected() {
      this.lesson.ref
        .collection("teachedTopics")
        .doc(
          "classGroup-" + this.$attrs.currenSelectedClassGroup.classGroupRef.id
        )
        .collection("dates")
        .doc(
          this.lesson.id +
            "-" +
            this.lesson.year +
            "-" +
            this.selectedMonth +
            "-" +
            this.selectedXXDay
        )
        .set(
          {
            teachedDay: this.selectedXXDay,
            teachedMonth: this.selectedMonth,
            teachedYear: this.lesson.year,
            lessonRef: this.lesson.ref,
            topic: this.selectedHutolbor,
          },
          { merge: true }
        );

      this.selectHutolborDialog = false;
    },
    _setTopic(head) {
      console.log(head);
      this.selectHutolborDialog = !this.selectHutolborDialog;
      this.selectedDay = head.text;
    },

    // _setStatus2(student, day, status) {
    //   if (status.value != 1) {
    //     var found = false;
    //     student.ref
    //       .collection(this.collectionName)
    //       .doc(this.lesson.id)
    //       .collection(this.docName)
    //       .onSnapshot((docs) => {
    //         docs.forEach((doc) => {
    //           if (found) {
    //             return;
    //           }
    //           if (
    //             doc.data().day == day &&
    //             doc.data().month == this.selectedMonth
    //           ) {
    //             doc.ref.set(
    //               {
    //                 status: status.value,
    //                 text: status.text,
    //                 day: day,
    //                 month: this.selectedMonth,
    //               },
    //               { merge: true }
    //             );
    //             found = true;
    //             return;
    //           }
    //         });
    //       });
    //     if (found == false) {
    //       student.ref
    //         .collection(this.collectionName)
    //         .doc(this.lesson.id)
    //         .collection(this.docName)
    //         .doc()
    //         .set(
    //           {
    //             day: day,
    //             status: status.value,
    //             text: status.text,
    //             createdAt: new Date(),
    //             month: this.selectedMonth,
    //           },
    //           { merge: true }
    //         );
    //     }
    //   } else {
    //     // student.ref
    //     //   .collection(this.collectionName)
    //     //   .doc(this.lesson.id)
    //     //   .collection(this.docName)
    //     //   .onSnapshot((docs) => {
    //     //     docs.forEach((doc) => {
    //     //       if (doc.data().day == day) {
    //     //         // student.ref.collection(this.collectionName)
    //     //         console.log(doc);
    //     //         fb.db.doc(doc.ref.path).delete();
    //     //       }
    //     //     });
    //     //   });

    //     if (student.attendances != null && student.attendances != undefined) {
    //       for (const attend of student.attendances) {
    //         if (attend.day == day) {
    //           attend.ref.delete();
    //         }
    //       }
    //     }
    //   }
    // },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
