var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-tabs',{staticClass:"mx-4",model:{value:(_vm.selectedClassGroupTab2),callback:function ($$v) {_vm.selectedClassGroupTab2=$$v},expression:"selectedClassGroupTab2"}},[_vm._l((_vm.selectedLessonClassGroupStudents),function(classGroup,cindex){return _c('v-tab',{key:cindex,class:{ 'active-tab': _vm.selectedClassGroupTab2 === cindex }},[_vm._v(_vm._s(classGroup.classGroupFullName)+" ")])}),_vm._l((_vm.selectedLessonClassGroupStudents),function(classGroup,cindex){return _c('v-tab-item',{key:cindex},[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('v-divider'),_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('v-card',{attrs:{"height":"580"}},[_c('v-row',[(_vm.currentLessonGroups2)?_c('v-col',[_c('v-tabs',{model:{value:(_vm.selectedLessonGroupTab),callback:function ($$v) {_vm.selectedLessonGroupTab=$$v},expression:"selectedLessonGroupTab"}},[_vm._l((_vm.selectedClassGroupLessonGroups),function(group,index){return _c('v-tab',{key:'lessonGroup-tab' + index,class:{
                      'active-tab2': _vm.selectedLessonGroupTab === index,
                    }},[_vm._v(_vm._s(group.name)+" "),(
                        _vm.currentSelectedLesson.lessonGroups.find(
                          (lg) =>
                            lg.groupIndex == group.groupIndex &&
                            lg.teacher.teacherRef.path == _vm.userData.ref.path &&
                            lg.classGroup.classGroupRef.id ==
                              classGroup.classGroupRef.id
                        ) &&
                        _vm.currentSelectedLesson.lessonGroups.find(
                          (lg) =>
                            lg.groupIndex == group.groupIndex &&
                            lg.teacher.teacherRef.path == _vm.userData.ref.path &&
                            lg.classGroup.classGroupRef.id ==
                              classGroup.classGroupRef.id
                        ).students
                      )?_c('span',[_vm._v(" ("+_vm._s(_vm.currentSelectedLesson.lessonGroups .find( (lg) => lg.groupIndex == group.groupIndex && lg.teacher.teacherRef.path == _vm.userData.ref.path && lg.classGroup.classGroupRef.id == classGroup.classGroupRef.id ) .students.filter( (stud) => stud.STUDENT_GROUP_ID == classGroup.classGroupRef.id ).length)+") ")]):_c('span',[_vm._v(" (0) ")])])}),_vm._l((_vm.selectedClassGroupLessonGroups),function(group,index){return _c('v-tab-item',{key:'lessonGroup' + index},[(group.teacher)?_c('h4',{staticClass:"my-2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$swal.fire(
                          group.teacher.teacherFirstName +
                            ', ' +
                            group.teacher.teacherLastName
                        )}}},[_c('span',{staticClass:"text-typo"},[_vm._v("Xичээл зааx багш: ")]),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(group.teacher.teacherFirstName))]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(", "+_vm._s(group.teacher.teacherLastName))])]):_c('h4',{staticClass:"red--text"},[_vm._v(" Xичээл зааx багшийг сонгоогүй байна ")]),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"text-end",attrs:{"cols":"8"}},[_c('v-switch',{staticClass:"ml-2",attrs:{"color":"primary","label":group.allStudentsSelected
                              ? 'Ангийн бүx сурагчдыг сонгосон байна'
                              : 'Ангийн бүx сурагчдыг сонгоx уу?'},on:{"change":function($event){return group.ref.update({
                              allStudentsSelected: group.allStudentsSelected,
                            })}},model:{value:(group.allStudentsSelected),callback:function ($$v) {_vm.$set(group, "allStudentsSelected", $$v)},expression:"group.allStudentsSelected"}})],1)],1),(
                        _vm.renderComponent2 &&
                        _vm.currentSelectedLesson.lessonGroups.find(
                          (lg) =>
                            lg.groupIndex == group.groupIndex &&
                            lg.teacher.teacherRef.path == _vm.userData.ref.path &&
                            lg.classGroup.classGroupRef.id ==
                              classGroup.classGroupRef.id
                        ) &&
                        _vm.currentSelectedLesson.lessonGroups.find(
                          (lg) =>
                            lg.groupIndex == group.groupIndex &&
                            lg.teacher.teacherRef.path == _vm.userData.ref.path &&
                            lg.classGroup.classGroupRef.id ==
                              classGroup.classGroupRef.id
                        ).students &&
                        !group.allStudentsSelected
                      )?_c('v-data-table',{attrs:{"height":"400","headers":_vm.headerStudentsTable,"items":_vm.currentSelectedLesson.lessonGroups
                          .find(
                            (lg) =>
                              lg.groupIndex == group.groupIndex &&
                              lg.teacher.teacherRef.path ==
                                _vm.userData.ref.path &&
                              lg.classGroup.classGroupRef.id ==
                                classGroup.classGroupRef.id
                          )
                          .students.filter(
                            (stud) =>
                              stud.STUDENT_GROUP_ID ==
                              classGroup.classGroupRef.id
                          ),"hide-details":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',{key:group.name + group.id + item.id,on:{"click":function($event){_vm._removeStudentFromGroup(
                              item,
                              _vm.currentSelectedLesson.lessonGroups.find(
                                (lg) =>
                                  lg.groupIndex == group.groupIndex &&
                                  lg.teacher.teacherRef.path ==
                                    _vm.userData.ref.path &&
                                  lg.classGroup.classGroupRef.id ==
                                    classGroup.classGroupRef.id
                              )
                            )}}},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item["FIRST_NAME"]))]),_c('td',[_vm._v(_vm._s(item["LAST_NAME"]))]),_c('td',[_vm._v(_vm._s(item["GENDER_CODE"]))])])]}}],null,true)}):(group.allStudentsSelected)?_c('v-alert',{staticClass:"mt-10",attrs:{"color":"green","text":"","prominent":"","icon":"mdi-check-circle-outline"}},[_vm._v(" Энэ группт ангийн бүx сурагчид xуваарилагдсан. Багшийн хичээл бүртгэл дээр бүх сурагчид харагдана! ")]):_vm._e()],1)})],2)],1):_vm._e(),_c('v-col',[_c('h4',{staticClass:"py-1 pl-2",staticStyle:{"background-color":"rgb(254, 254, 168)"}},[_vm._v(" Үлдсэн сурагчид ( "),(
                      _vm.selectedLessonClassGroupStudents.find(
                        (cg) =>
                          cg.classGroupRef.id == classGroup.classGroupRef.id
                      ) &&
                      _vm.selectedLessonClassGroupStudents.find(
                        (cg) =>
                          cg.classGroupRef.id == classGroup.classGroupRef.id
                      ).students
                    )?_c('span',[_vm._v(_vm._s(_vm.selectedLessonClassGroupStudents.find( (cg) => cg.classGroupRef.id == classGroup.classGroupRef.id ).students.length))]):_vm._e(),_vm._v(" ) ")]),(
                    _vm.renderComponent2 &&
                    _vm.selectedLessonClassGroupStudents.find(
                      (cg) =>
                        cg.classGroupRef.id == classGroup.classGroupRef.id
                    ) &&
                    _vm.selectedLessonClassGroupStudents.find(
                      (cg) =>
                        cg.classGroupRef.id == classGroup.classGroupRef.id
                    ).students
                  )?_c('v-data-table',{attrs:{"height":"480","fixed-header":"","headers":_vm.headerStudentsTable,"items":_vm.selectedLessonClassGroupStudents.find(
                      (cg) =>
                        cg.classGroupRef.id == classGroup.classGroupRef.id
                    ).students,"hide-details":"","hide-default-footer":"","items-per-page":-1},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',{on:{"click":function($event){_vm.handleRowClick(
                          item,
                          _vm.currentSelectedLesson.lessonGroups.find(
                            (lg) =>
                              lg.groupIndex ==
                                _vm.currentLessonGroups2.filter(
                                  (lg) =>
                                    lg.classGroup.classGroupRef.id ==
                                    classGroup.classGroupRef.id
                                )[_vm.selectedLessonGroupTab].groupIndex &&
                              lg.teacher.teacherRef.path ==
                                _vm.userData.ref.path &&
                              lg.classGroup.classGroupRef.id ==
                                classGroup.classGroupRef.id
                          ),
                          _vm.selectedLessonClassGroupStudents.find(
                            (cg) =>
                              cg.classGroupRef.id ==
                              classGroup.classGroupRef.id
                          )
                        )}}},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item["FIRST_NAME"]))]),_c('td',[_vm._v(_vm._s(item["LAST_NAME"]))]),_c('td',[_vm._v(_vm._s(item["GENDER_CODE"]))]),_c('td',[_vm._v(_vm._s(item["inGroup"]))])])]}}],null,true)}):_vm._e()],1)],1)],1)],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }