<template>
  <v-card class="px-0 pb-10">
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text class="px-0 py-0 scoreTabless mt-4 mb-10">
      <template v-if="loading == false">
        <template
          v-if="$attrs.lesson.isGroupBased && $attrs.lesson.lessonGroups"
        >
          <v-simple-table v-if="xStudents">
            <tbody>
              <template v-for="(lg, lgIndex) in $attrs.lesson.lessonGroups">
                <div :key="'lg' + lg.id + lgIndex + 'group'">
                  <h2 class="mt-10 blue--text">{{ lg.name }}</h2>
                </div>
                <tr :key="'lg' + lg.id + lgIndex" class="mt-10 pt-10">
                  <th style="width: 1%">No.</th>
                  <th style="width: 15%">Нэр</th>
                  <th
                    v-for="header in _getFilteredGroupHeader(
                      $attrs.examInfos,
                      lg
                    )"
                    :key="header.id"
                    class="text-center"
                  >
                    {{ _getShortExamName(header) }}
                    <p class="blue--text">{{ header.examDate }}</p>
                  </th>
                </tr>

                <tr
                  v-for="(item, ii) in lg.students"
                  :key="lg.id + ii + item.id"
                >
                  <td style="width: 1%" class="pl-1" @click="_print(item)">
                    {{ ii + 1 }}
                  </td>
                  <td class="pl-1" style="width: 15%">
                    {{ item["LAST_NAME"][0] + ". " + item["FIRST_NAME"] }}
                  </td>
                  <td
                    @click="_printScore(item, exam, lg)"
                    v-for="exam in _getFilteredGroupHeader(
                      $attrs.examInfos,
                      lg
                    )"
                    :key="'exam-' + exam.id"
                    style="width: 1%"
                    class="text-center pl-0"
                  >
                    <v-row no-gutters>
                      <v-col
                        class="pa-0"
                        style="
                          height: 25px;
                          padding-top: 2px !important;
                          border-right: 2px solid #dddddd;
                        "
                        >{{ _getExamScore(item, exam, lg) }}</v-col
                      >
                      <v-col
                        class="px-a"
                        style="height: 25px; padding-top: 2px !important"
                        >{{ _getExamScore2(item, exam, lg) }}</v-col
                      >
                    </v-row>
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </template>
        <template
          v-else-if="$attrs.lesson.isGroupBased && $attrs.lesson.subLessons"
        >
          <v-simple-table v-if="xStudents">
            <tbody>
              <template v-for="(lg, lgIndex) in $attrs.lesson.subLessons">
                <div :key="'lg' + lg.id + lgIndex + 'group'">
                  <h2 class="mt-10 blue--text">
                    <span v-if="lg.classGroups[0]">{{
                      lg.classGroups[0].classGroupFullName
                    }}</span>
                    <span v-else>--</span>
                  </h2>
                </div>
                <tr :key="'lg' + lg.id + lgIndex" class="mt-10 pt-10">
                  <th style="width: 1%">No.</th>
                  <th style="width: 15%">Нэр</th>
                  <th
                    v-for="header in _getFilteredGroupHeader(
                      $attrs.examInfos,
                      lg
                    )"
                    :key="header.id"
                    class="text-center"
                  >
                    {{ _getShortExamName(header) }}
                    <p class="blue--text">{{ header.examDate }}</p>
                  </th>
                </tr>

                <tr v-for="(item, ii) in xStudents" :key="lg.id + ii + item.id">
                  <template
                    v-if="
                      item.classGroup.classGroupRef.path ==
                      lg.classGroups[0].classGroupRef.path
                    "
                  >
                    <td style="width: 1%" class="pl-1" @click="_print(item)">
                      {{ ii + 1 }}
                    </td>
                    <td class="pl-1" style="width: 15%">
                      {{ item["LAST_NAME"][0] + ". " + item["FIRST_NAME"] }}
                    </td>
                    <td
                      @click="_printScore(item, exam, lg)"
                      v-for="exam in _getFilteredGroupHeader(
                        $attrs.examInfos,
                        lg
                      )"
                      :key="'exam-' + exam.id"
                      style="width: 1%"
                      class="text-center pl-0"
                    >
                      <v-row no-gutters>
                        <v-col
                          class="pa-0"
                          style="
                            height: 25px;
                            padding-top: 2px !important;
                            border-right: 2px solid #dddddd;
                          "
                          >{{ _getExamScore(item, exam, null) }}</v-col
                        >
                        <v-col
                          class="pa-0"
                          style="height: 25px; padding-top: 2px !important"
                          >{{ _getExamScore2(item, exam, null) }}</v-col
                        >
                      </v-row>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </template>
        <template v-else>
          <v-data-table
            v-if="xStudents"
            :headers="$attrs.examInfos"
            :items="xStudents"
            :items-per-page="-1"
            :search="search"
            hide-default-header
            hide-details
            hide-default-footer
          >
            <template v-slot:header="{ props: { headers } }">
              <tr>
                <th style="width: 1%">No.</th>
                <th style="width: 15%">Нэр</th>
                <!-- Customized headers -->
                <th
                  v-for="header in headers"
                  :key="header.id"
                  class="text-center"
                >
                  {{ _getShortExamName(header) }}
                  <p class="blue--text">{{ header.examDate }}</p>
                  <!-- <p>{{ header.id }}</p> -->
                </th>
              </tr>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td style="width: 1%" class="pl-1" @click="_print(props.item)">
                  {{ props.item.numbering }}
                </td>
                <td class="pl-1">
                  {{
                    props.item["LAST_NAME"][0] + ". " + props.item["FIRST_NAME"]
                  }}
                </td>
                <td
                  @click="_printScore(props.item, exam)"
                  v-for="exam in $attrs.examInfos"
                  :key="'exam-' + exam.id"
                  style="width: 3%"
                  class="text-center pl-0"
                >
                  <v-row no-gutters>
                    <v-col
                      class="pa-0"
                      style="
                        height: 25px;
                        padding-top: 2px !important;
                        border-right: 2px solid #dddddd;
                      "
                      >{{ _getExamScore(props.item, exam) }}</v-col
                    >
                    <v-col
                      class="pa-0"
                      style="height: 25px; padding-top: 2px !important"
                      >{{ _getExamScore2(props.item, exam) }}</v-col
                    >
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </template>
      <div v-else>
        <v-card height="400">
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  name: "light-table-transparent",
  data() {
    return {
      currentSelectedExam: null,
      actionstatus: null,
      search: "",
      snackbar: false,
      headers2: [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          fixed: true,
        },
        {
          text: "Нэр",
          align: "start",
          value: "firstName",
          sortable: true,
          fixed: true,
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    xStudents() {
      var list = [];
      var counter = 0;
      if (this.$attrs.students) {
        for (const stud of this.$attrs.students) {
          counter++;
          stud.numbering = counter;
          list.push(stud);
        }
      }
      return list;
    },
  },
  methods: {
    _getFilteredGroupHeader(examInfos, lg) {
      var list = [];
      for (const exam of examInfos) {
        if (exam.lessonGroup && exam.lessonGroup.id == lg.id) {
          list.push(exam);
        } else if (!exam.lessonGroup) {
          list.push(exam);
        }
      }
      return list;
    },
    _doUnelgee(item) {
      this.currentSelectedExam = item;
      console.log(this.$attrs.allScores);
      this.$attrs.students.forEach((stud) => {
        var ss = this.$attrs.allScores.find(
          (sc) =>
            sc.examId == this.currentSelectedExam.id &&
            stud.PERSON_ID == sc.PERSON_ID
        );
        console.log(ss);
        if (ss) stud.score = ss.score;
        else stud.score = null;
      });
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    _printScore(student, exam, lg) {
      console.log(student.scores, exam.id, exam.ref.path);
      if (lg) {
        console.log(this._getExamScore(student, exam, lg));
      }
    },
    _print(item) {
      console.log(item.ref.path);
    },

    _getExamScore2(student, exam, lGroup) {
      if (this.$attrs.allScores) {
        if (lGroup) {
          var ss1 = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id &&
              student.PERSON_ID == score.PERSON_ID &&
              score.lessonGroupId == lGroup.id
          );
          if (ss1) {
            return ss1.scoreLevelString;
          } else return null;
        } else {
          var ss = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
          );
          if (ss) {
            // console.log(student.firstName, student.ref.path, ss.score)
            return ss.scoreLevelString;
          } else return null;
        }
      }
    },
    _getExamScore(student, exam, lGroup) {
      if (this.$attrs.allScores) {
        if (lGroup) {
          var ss1 = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id &&
              student.PERSON_ID == score.PERSON_ID &&
              score.lessonGroupId == lGroup.id
          );
          if (ss1) {
            return ss1.score;
          } else return null;
        } else {
          var ss = this.$attrs.allScores.find(
            (score) =>
              score.examId == exam.id && student.PERSON_ID == score.PERSON_ID
          );
          if (ss) {
            // console.log(student.firstName, student.ref.path, ss.score)
            return ss.score;
          } else return null;
        }
      }
    },
    _getShortExamName(header) {
      var name = null;
      if (header.examTopic) name = header.examTopic.parentCourseTopicName;
      else name = header.examName ? header.examName : null;
      if (name) {
        if (name.length < 40) return name;
        else return name.substring(0, 40) + " ...";
      }
      return name;
    },
    async finalScoreChanged(student, semesterNumber) {
      console.log(student.ref.path, this.lesson.ref);
      this.lesson.ref
        .collection("studentList")
        .doc(student.id)
        .set({ scores: student.scores }, { merge: true })
        .then(() => {
          // student.ref.collection("finalGrades").doc(this.lesson.id).set(
          //   {
          //     scores: student.scores,
          //     semester: 1
          //   },
          //   { merge: true }
          // );

          this.actionstatus =
            student.firstName +
            ", " +
            student.scores[semesterNumber - 1].finalScore +
            " дүн амжилттай xадгалагдлаа.";
          this.snackbar = true;
          document.getElementById(student.id + semesterNumber).blur();
        });
    },
  },
  async created() {
    this.headers2 = this.$attrs.examInfos;
    if (this.$attrs.students) {
      this.loading = true;
      this.loading = false;
    }
  },
};
</script>
<style>
.scoreTabless td,
.scoreTabless th {
  padding: 0px !important;
  padding-left: 5px !important;
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
