<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <EeljTable
        :item="{ name: 'Xичээлийн ээлжийн цаг', fbpath: 'xeelj' }"
      ></EeljTable>
    </v-container>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  components: {
    EeljTable: () => import("./Widgets/XEeljTable"),
  },
  data: () => ({}),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {},
  methods: {},
};
</script>
 