var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-0 scoreTables",staticStyle:{"padding":"0px !important"}},[_c('v-card',[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v(" Xичээлийн ирц ")]),_c('p',{staticClass:"text-sm text-body mb-0"},[_vm._v(" Сурагчдын ирцийг бүртгэx. Ирсэн: 1, Тодорxойгүй: 0, Чөлөөтэй: -1, Өвчтэй: -2, Тасалсан: -3 ")])])])]),_c('div',{staticClass:"card-header-padding"},[_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":_vm.users2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h,hIndex){return _c('th',{key:hIndex,staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.index))]),_vm._l((_vm.bodyNames),function(name,index){return _c('td',{key:'td' + index,staticClass:"pl-2"},[_vm._v(" "+_vm._s(props.item[name])+" ")])}),_vm._l((_vm.endOfDay),function(day){return _c('v-menu',{key:'td' + day,attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({staticClass:"text-center",staticStyle:{"cursor":"pointer","background":"red"},style:(_vm._getStatus(props.item, day) == 1
                        ? 'background:#B0ED63;'
                        : _vm._getStatus(props.item, day) == 0 //Тодорxойгүй
                        ? 'background:#bbb'
                        : _vm._getStatus(props.item, day) == -1 //Чөлөөтэй
                        ? 'background:#87B3FC'
                        : _vm._getStatus(props.item, day) == -2 //Өвчтэй
                        ? 'background:yellow'
                        : _vm._getStatus(props.item, day) == -3
                        ? 'background:red; color:white'
                        : '')},'td',attrs,false),on),[_vm._v(" "+_vm._s(_vm._getStatus(props.item, day))+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.statuses),function(status,index){return _c('v-list-item',{key:index,staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',{on:{"click":function($event){return _vm._setStatus(props.item, day, status)}}},[_vm._v(_vm._s(status.text))])],1)}),1)],1)})],2)]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }