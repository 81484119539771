<template>
  <v-card>
    <v-tabs v-model="selectedClassGroupTab2" class="mx-4">
      <v-tab
        :class="{ 'active-tab': selectedClassGroupTab2 === cindex }"
        v-for="(classGroup, cindex) in selectedLessonClassGroupStudents"
        :key="cindex"
        >{{ classGroup.classGroupFullName }}
        <!-- - {{ classGroup.classGroupRef.id }} -->
      </v-tab>
      <v-tab-item
        v-for="(classGroup, cindex) in selectedLessonClassGroupStudents"
        :key="cindex"
      >
        <v-card class="card-shadow border-radius-xl">
          <v-divider></v-divider>
          <div class="card-header-padding card-border-bottom">
            <v-card height="580">
              <v-row>
                <v-col v-if="currentLessonGroups2">
                  <v-tabs v-model="selectedLessonGroupTab">
                    <v-tab
                      :class="{
                        'active-tab2': selectedLessonGroupTab === index,
                      }"
                      v-for="(group, index) in selectedClassGroupLessonGroups"
                      :key="'lessonGroup-tab' + index"
                      >{{ group.name }}
                      <span
                        v-if="
                          currentSelectedLesson.lessonGroups.find(
                            (lg) =>
                              lg.groupIndex == group.groupIndex &&
                              lg.teacher.teacherRef.path == userData.ref.path &&
                              lg.classGroup.classGroupRef.id ==
                                classGroup.classGroupRef.id
                          ) &&
                          currentSelectedLesson.lessonGroups.find(
                            (lg) =>
                              lg.groupIndex == group.groupIndex &&
                              lg.teacher.teacherRef.path == userData.ref.path &&
                              lg.classGroup.classGroupRef.id ==
                                classGroup.classGroupRef.id
                          ).students
                        "
                      >
                        ({{
                          currentSelectedLesson.lessonGroups
                            .find(
                              (lg) =>
                                lg.groupIndex == group.groupIndex &&
                                lg.teacher.teacherRef.path ==
                                  userData.ref.path &&
                                lg.classGroup.classGroupRef.id ==
                                  classGroup.classGroupRef.id
                            )
                            .students.filter(
                              (stud) =>
                                stud.STUDENT_GROUP_ID ==
                                classGroup.classGroupRef.id
                            ).length
                        }})
                      </span>
                      <span v-else> (0) </span>
                    </v-tab>
                    <v-tab-item
                      v-for="(group, index) in selectedClassGroupLessonGroups"
                      :key="'lessonGroup' + index"
                    >
                      <!-- <p @click="_printGroup(group)">
                        {{ group.classGroup.classGroupRef.id }}
                      </p> -->
                      <h4
                        v-if="group.teacher"
                        class="my-2"
                        style="cursor: pointer"
                        @click="
                          $swal.fire(
                            group.teacher.teacherFirstName +
                              ', ' +
                              group.teacher.teacherLastName
                          )
                        "
                      >
                        <span class="text-typo">Xичээл зааx багш: </span>
                        <span class="red--text">{{
                          group.teacher.teacherFirstName
                        }}</span
                        ><span class="font-weight-normal"
                          >, {{ group.teacher.teacherLastName }}</span
                        >
                      </h4>
                      <h4 v-else class="red--text">
                        Xичээл зааx багшийг сонгоогүй байна
                      </h4>
                      <v-row justify="space-between">
                        <v-col class="text-end" cols="8">
                          <v-switch
                            class="ml-2"
                            color="primary"
                            @change="
                              group.ref.update({
                                allStudentsSelected: group.allStudentsSelected,
                              })
                            "
                            v-model="group.allStudentsSelected"
                            :label="
                              group.allStudentsSelected
                                ? 'Ангийн бүx сурагчдыг сонгосон байна'
                                : 'Ангийн бүx сурагчдыг сонгоx уу?'
                            "
                          ></v-switch>
                          <!-- <v-btn
                                v-if="
                                  !group.teacher &&
                                  group.students &&
                                  group.students.length == 0
                                "
                                class="bg-gradient-danger mr-2"
                                small
                                dark
                                @click="_deleteGroup"
                                >Групп устгаx</v-btn
                              > -->
                        </v-col>
                      </v-row>
                      <v-data-table
                        height="400"
                        v-if="
                          renderComponent2 &&
                          currentSelectedLesson.lessonGroups.find(
                            (lg) =>
                              lg.groupIndex == group.groupIndex &&
                              lg.teacher.teacherRef.path == userData.ref.path &&
                              lg.classGroup.classGroupRef.id ==
                                classGroup.classGroupRef.id
                          ) &&
                          currentSelectedLesson.lessonGroups.find(
                            (lg) =>
                              lg.groupIndex == group.groupIndex &&
                              lg.teacher.teacherRef.path == userData.ref.path &&
                              lg.classGroup.classGroupRef.id ==
                                classGroup.classGroupRef.id
                          ).students &&
                          !group.allStudentsSelected
                        "
                        :headers="headerStudentsTable"
                        :items="
                          currentSelectedLesson.lessonGroups
                            .find(
                              (lg) =>
                                lg.groupIndex == group.groupIndex &&
                                lg.teacher.teacherRef.path ==
                                  userData.ref.path &&
                                lg.classGroup.classGroupRef.id ==
                                  classGroup.classGroupRef.id
                            )
                            .students.filter(
                              (stud) =>
                                stud.STUDENT_GROUP_ID ==
                                classGroup.classGroupRef.id
                            )
                        "
                        hide-details
                        hide-default-footer
                        :items-per-page="-1"
                      >
                        <template v-slot:item="{ item, index }">
                          <tr
                            :key="group.name + group.id + item.id"
                            @click="
                              _removeStudentFromGroup(
                                item,
                                currentSelectedLesson.lessonGroups.find(
                                  (lg) =>
                                    lg.groupIndex == group.groupIndex &&
                                    lg.teacher.teacherRef.path ==
                                      userData.ref.path &&
                                    lg.classGroup.classGroupRef.id ==
                                      classGroup.classGroupRef.id
                                )
                              )
                            "
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item["FIRST_NAME"] }}</td>
                            <td>{{ item["LAST_NAME"] }}</td>
                            <td>{{ item["GENDER_CODE"] }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-alert
                        class="mt-10"
                        v-else-if="group.allStudentsSelected"
                        color="green"
                        text
                        prominent
                        icon="mdi-check-circle-outline"
                      >
                        Энэ группт ангийн бүx сурагчид xуваарилагдсан. Багшийн
                        хичээл бүртгэл дээр бүх сурагчид харагдана!
                      </v-alert>
                    </v-tab-item>
                  </v-tabs>
                </v-col>
                <v-col>
                  <h4
                    style="background-color: rgb(254, 254, 168)"
                    class="py-1 pl-2"
                  >
                    Үлдсэн сурагчид (
                    <span
                      v-if="
                        selectedLessonClassGroupStudents.find(
                          (cg) =>
                            cg.classGroupRef.id == classGroup.classGroupRef.id
                        ) &&
                        selectedLessonClassGroupStudents.find(
                          (cg) =>
                            cg.classGroupRef.id == classGroup.classGroupRef.id
                        ).students
                      "
                      >{{
                        selectedLessonClassGroupStudents.find(
                          (cg) =>
                            cg.classGroupRef.id == classGroup.classGroupRef.id
                        ).students.length
                      }}</span
                    >
                    )
                  </h4>
                  <v-data-table
                    v-if="
                      renderComponent2 &&
                      selectedLessonClassGroupStudents.find(
                        (cg) =>
                          cg.classGroupRef.id == classGroup.classGroupRef.id
                      ) &&
                      selectedLessonClassGroupStudents.find(
                        (cg) =>
                          cg.classGroupRef.id == classGroup.classGroupRef.id
                      ).students
                    "
                    height="480"
                    fixed-header
                    :headers="headerStudentsTable"
                    :items="
                      selectedLessonClassGroupStudents.find(
                        (cg) =>
                          cg.classGroupRef.id == classGroup.classGroupRef.id
                      ).students
                    "
                    @click:row="handleRowClick"
                    hide-details
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr
                        @click="
                          handleRowClick(
                            item,
                            currentSelectedLesson.lessonGroups.find(
                              (lg) =>
                                lg.groupIndex ==
                                  currentLessonGroups2.filter(
                                    (lg) =>
                                      lg.classGroup.classGroupRef.id ==
                                      classGroup.classGroupRef.id
                                  )[selectedLessonGroupTab].groupIndex &&
                                lg.teacher.teacherRef.path ==
                                  userData.ref.path &&
                                lg.classGroup.classGroupRef.id ==
                                  classGroup.classGroupRef.id
                            ),
                            selectedLessonClassGroupStudents.find(
                              (cg) =>
                                cg.classGroupRef.id ==
                                classGroup.classGroupRef.id
                            )
                          )
                        "
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item["FIRST_NAME"] }}</td>
                        <td>{{ item["LAST_NAME"] }}</td>
                        <td>{{ item["GENDER_CODE"] }}</td>
                        <td>{{ item["inGroup"] }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
export default {
  props: {
    headerStudentsTable: {
      type: Array,
      required: true,
    },
    selectedLessonClassGroupStudents: {
      type: Array,
      required: true,
    },
    currentLessonGroups: {
      type: Array,
      required: true,
    },
    currentSelectedLesson: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    // showSettingsDrawer: {
    //   type: Boolean,
    //   default: false,
    // },
    // navbarFixed: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      renderComponent2: true,
      selectedClassGroupTab2: 0,
      selectedLessonGroupTab: 0,
      currentLessonGroups2: null,
    };
  },
  created() {
    this.currentLessonGroups2 = [];
    this.currentLessonGroups.forEach((lg) => {
      if (
        this.currentLessonGroups2.find((lgg) => lgg.id == lg.id) == undefined
      ) {
        this.currentLessonGroups2.push(lg);
      }
    });
  },
  computed: {
    selectedClassGroupLessonGroups() {
      console.log(
        this.selectedLessonClassGroupStudents,
        this.selectedLessonClassGroupStudents[this.selectedClassGroupTab2]
      );
      if (
        this.selectedLessonClassGroupStudents &&
        this.selectedLessonClassGroupStudents[this.selectedClassGroupTab2]
      ) {
        return this.currentLessonGroups2.filter(
          (cg) =>
            cg.classGroup.classGroupRef.id ==
            this.selectedLessonClassGroupStudents[this.selectedClassGroupTab2]
              .classGroupRef.id
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    _printGroup(group) {
      console.log(group, group.ref.path);
    },
    _removeStudentFromGroup(gstudent, lessonGroup) {
      console.log(lessonGroup);
      lessonGroup.ref
        .collection("students")
        .doc(gstudent.id)
        .delete()
        .then(() => {
          var cg = this.selectedLessonClassGroupStudents.find(
            (cg) => cg.classGroupRef.id == gstudent.STUDENT_GROUP_ID
          );
          cg.students.push(gstudent);
          cg.students.sort((a, b) =>
            a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
          );
          lessonGroup.students.splice(
            lessonGroup.students.indexOf(gstudent),
            1
          );
          this.forceRerender2();
        });
    },
    async forceRerender2() {
      this.renderComponent2 = false;
      await this.$nextTick();
      this.renderComponent2 = true;
    },
    handleRowClick(student, lessonGroup, classGroup) {
      if (
        this.currentSelectedLesson.lessonGroups &&
        this.currentSelectedLesson.lessonGroups.length > 0
      ) {
        lessonGroup.ref
          .collection("students")
          .doc(student.id)
          .set(
            {
              PERSON_ID: student["PERSON_ID"],
              FIRST_NAME: student["FIRST_NAME"],
              LAST_NAME: student["LAST_NAME"],
              GENDER_CODE: student["GENDER_CODE"],
              STUDENT_GROUP_ID: student["STUDENT_GROUP_ID"],
              STUDENT_GROUP_NAME: student["STUDENT_GROUP_NAME"],
            },
            { merge: true }
          )
          .then(() => {
            var indexToDelete = classGroup.students.findIndex(
              (t) => t.id == student.id
            );
            classGroup.students.splice(indexToDelete, 1);
            lessonGroup.students.push({
              PERSON_ID: student["PERSON_ID"],
              FIRST_NAME: student["FIRST_NAME"],
              LAST_NAME: student["LAST_NAME"],
              GENDER_CODE: student["GENDER_CODE"],
              STUDENT_GROUP_ID: student["STUDENT_GROUP_ID"],
              STUDENT_GROUP_NAME: student["STUDENT_GROUP_NAME"],
              id: student.id,
              ref: lessonGroup.ref.collection("students").doc(student.id),
            });
            lessonGroup.students.sort((a, b) =>
              a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
            );
            this.forceRerender2();
          });
      } else {
        this.$swal.fire("Групп+ товч ашиглаад группэээxлээд үүсгэнэ үү.");
      }
    },
  },
};
</script>
