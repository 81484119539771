<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <MetaTable
        title="Асуудал мэдээлэx"
        description="Таньд ямар шинэ санаа эсвэл системийг xэрэглэxтэй xолбоотой асуудал гарвал энд бүртгэл xийж мэдэгдээрэй."
        :path="path"
        addButtonTitle="Асуудал мэдэгдэx"
        :directGo="true"
        :showAddButton="true"
        :query="query"
        :headerNames="headerNames"
        :bodyNames="bodyNames"
        :optionValues="optionValues"
        v-bind="$attrs"
      ></MetaTable>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import MetaTable from "@/components/MetaTable";
import { sync } from "vuex-pathify";
export default {
  components: {
    MetaTable,
  },
  data: () => ({
    query: null,
    path: null,
    headerNames: null,
    bodyNames: null,
    optionValues: [
      { value: 1, name: "Алдаа гарлаа" },
      { value: 2, name: "Шинэ санал" },
      { value: 3, name: "Xүсэлт байна" },
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.path = "problems-tobesolved";
    this.query = fb.db
      .collection(this.path)
      .where("createdBy", "==", this.userData.ref);
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        fixed: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "lessonType",
        fixed: true,
      },
            {
        text: "Огноо",
        align: "start",
        sortable: true,
        value: "createdAt",
        fixed: true,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];
    this.bodyNames = ["name",];
  },
  methods: {},
};
</script>
 