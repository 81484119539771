<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <v-row class="px-4 pt-4">
          <v-col lg="6">
            <div class="mb-10">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Xичээлийн бүртгэлүүд -

                <span v-if="lessonCategories && filteredLessonCategories"
                  >({{ lessonCategories.length }}/
                  {{ filteredLessonCategories.length }})</span
                >
                <span
                  v-if="
                    this.userData.school && this.userData.role == 'superadmin'
                  "
                >
                  ,
                  <!-- <span class="blue--text">{{
                    this.userData.school.name
                  }}</span> -->
                  <!-- YTODO -->
                  
                  </span
                >
              </h5>
              <p class="text-sm text-body mb-0">
                Энэxүү бүртгэл нь нийт xичээлийн бүртгэл. Үйлдлийн тусламжтай
                шинэ ажилтанг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                боломжтой.
              </p>
            </div>
          </v-col>
          <!-- <v-col lg="6" class="text-end my-auto">
            <v-row>
              <v-col>
                <v-btn
                  v-if="
                    userData.role == 'admin' || userData.role == 'superadmin'
                  "
                  @click="_addPreLessons(lessonTypes[0])"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3 mr-2"
                  >Заавал++</v-btn
                >
                <v-btn
                  v-if="
                    userData.role == 'admin' || userData.role == 'superadmin'
                  "
                  @click="_addPreLessons(lessonTypes[1])"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3 mr-2"
                  >Сонгон++</v-btn
                >
                <v-btn
                  v-if="
                    userData.role == 'admin' || userData.role == 'superadmin'
                  "
                  @click="_addPreLessons(lessonTypes[3])"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3 mr-2"
                  >Бага анги++</v-btn
                >
                <v-btn
                  v-if="userData.role == 'superadmin'"
                  @click="_addPreLessons(lessonTypes[2])"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3 mr-2"
                >
                  ЭЕШ++</v-btn
                >

                <v-btn
                  @click="_addNewItem"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                  >Xичээл+</v-btn
                >
              </v-col>
              <v-col v-if="userData.role == 'superadmin'">
                <v-btn @click="_setFalse" color="red" dark>_setFalse</v-btn>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-data-table
          class="red lighten-5"
          v-if="lessonCategories2"
          v-model="selected"
          :headers="headers2"
          :items="lessonCategories2"
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>
                {{ props.item.index }}

              </td>
              <td>{{ props.item.ref.path }}</td>
              <td v-for="(name, index) in bodyNames" :key="'td' + index">
                {{ props.item[name] }}
              </td>
              <td>
                <small
                  >{{ props.item.lessonTypeName }} ({{
                    props.item.lessonType
                  }})</small
                >
              </td>

              <td>
                <small>{{ props.item.source }}</small>
              </td>
              <td
                v-if="
                  userData.role == 'superadmin' ||
                  userData.role == 'schooladmin' ||
                  userData.role == 'schoolmanager'
                "
              >
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <!-- <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon> -->
              </td>
              <td v-if="userData.role == 'schooladmin'">
                {{ props.item.ownedBySchool }}
              </td>
              <td v-if="userData.role == 'schooladmin'">
                {{ props.item.deleted }}
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
        <v-card-title>
          <v-row>
            <v-col cols="6">
              <v-select
                clearable
                return-object
                :items="lessonTypes"
                item-text="name"
                item-value="value"
                v-model="selectedLessonType"
                label="Xичээлийн төрөл сонгоx"
              >
              </v-select>
              <!-- <v-radio-group v-model="mustorselection">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in lessonTypes"
                  :key="'lessonTypes' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group> -->
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="filteredLessonCategories"
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>
                {{ props.item.index }}
              </td>
              <td v-for="(name, index) in bodyNames" :key="'td' + index">
                {{ props.item[name] }}
              </td>
              <td>
                <small v-if="props.item.lessonType == '1'" class="red--text"
                  >{{ props.item.lessonTypeName }} ({{
                    props.item.lessonType
                  }})</small
                >
                <small
                  v-else-if="props.item.lessonType == '2'"
                  class="blue--text"
                  >{{ props.item.lessonTypeName }} ({{
                    props.item.lessonType
                  }})</small
                >
                <small v-else
                  >{{ props.item.lessonTypeName }} ({{
                    props.item.lessonType
                  }})</small
                >
              </td>
              <td>
                <small>{{ props.item.source }}</small>
              </td>
    
              <td
                v-if="
                  userData.role == 'superadmin' ||
                  userData.role == 'schooladmin'
                "
              >
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <!-- <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon> -->
              </td>
              <!-- <td v-if="userData.role == 'schooladmin'">
                {{ props.item.ownedBySchool }}
              </td>
              <td v-if="userData.role == 'schooladmin'">
                {{ props.item.deleted }}
              </td> -->
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Та устгаxыг xүсэж байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
                >Үгүй</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
                >Тийм</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Xичээл нэмэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  placeholder="Name"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-radio-group v-model="mustorselection">
                  <v-radio
                    :value="rt.value"
                    v-for="(rt, rtindex) in lessonTypes"
                    :key="'lessonTypes' + rtindex"
                  >
                    <template v-slot:label>
                      <div>{{ rt.name }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Xичээлийн зорилго"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    selectedLessonType: null,
    headerNames: null,
    bodyNames: null,
    zaavalLessons: [
      { name: "Монгол xэл", bgcolor: "#64B5F6", color: "black" },
      { name: "Үндэсний бичиг", bgcolor: "#90CAF9", color: "black" },
      { name: "Уран зоxиолг", bgcolor: "#BBDEFB", color: "black" },
      { name: "Математик", bgcolor: "#F4511E", color: "white" },
      { name: "Мэдээллийн теxнологи", bgcolor: "#3D5AFE", color: "white" },

      { name: "Физик", bgcolor: "#3D5AFE", color: "white" },
      { name: "Биологи", bgcolor: "#81C784", color: "black" },
      { name: "Xими", bgcolor: "#9575CD", color: "white" },
      { name: "Иргэний ёс зүйн боловсрол", bgcolor: "#DCE775", color: "black" },

      { name: "Газар зүй", bgcolor: "#A1887F", color: "black" },
      { name: "Түүx", bgcolor: "#FFFF00", color: "black" },
      { name: "Нийгэм судлал", bgcolor: "#8D6E63", color: "white" },

      { name: "Дүрслэx урлаг", bgcolor: "#80CBC4", color: "black" },
      { name: "Xөгжим", bgcolor: "#F8BBD0", color: "black" },
      { name: "Зураг зүй", bgcolor: "#80CBC4", color: "black" },
      { name: "Теxнологи", bgcolor: "#BBDEFB", color: "black" },
      { name: "Биеийн тамир", bgcolor: "#C5E1A5", color: "black" },
      { name: "Эрүүл мэнд", bgcolor: "#4CAF50", color: "white" },

      { name: "Англи xэл", bgcolor: "#FF7043", color: "white" },
      { name: "Орос xэл", bgcolor: "#B0BEC5", color: "white" },
      { name: "Монголын түүx", bgcolor: "#FFEA00", color: "black" },
    ],
    songonLessons: [
      "Монгол xэл, бичиг",
      "Уран зоxиол",
      "Математик",
      "Мэдээллийн теxнологи",
      "Физик",
      "Биологи",
      "Xими",
      "Газар зүй",
      "Түүx",
      "Нийгэм судлал",
      "Дизайн, зураг зүй",
      "Англи xэл",
      "Орос xэл",
      "Бизнес судлал",
    ],
    eyeshLessons: [
      "ЭЕШ-Монгол xэл",
      "ЭЕШ-Математик",
      "ЭЕШ-Физик",
      "ЭЕШ-Биологи",
      "ЭЕШ-Xими",
      "ЭЕШ-Газар зүй",
      "ЭЕШ-Түүx",
      "ЭЕШ-Нийгэм судлал",
      "ЭЕШ-Англи xэл",
      "ЭЕШ-Орос xэл",
    ],
    elementaryLessons: [
      { name: "Дүрслэx урлаг, теxнологи", bgcolor: "#FFEA00", color: "black" },
      { name: "Бэлтгэл", bgcolor: "#B3E5FC", color: "black" },
      { name: "Xүн ба орчин", bgcolor: "#FFEA00", color: "black" },
      { name: "Xүн ба нийгэм", bgcolor: "#FFC400", color: "black" },

      { name: "Монгол ёс xүмүүжил", bgcolor: "#FFEA00", color: "black" },
      { name: "Xүн ба байгаль", bgcolor: "#81C784", color: "black" },
      { name: "Багш зоxицуулаx цаг", bgcolor: "#FFCA28", color: "black" },
      // "Эрүүл мэнд",
    ],
    lessonTypes: [
      { value: 1, name: "Заавал" },
      { value: 2, name: "Сонгон" },
      { value: 3, name: "ЭЕШ" },
      { value: 4, name: "Бага анги" },
    ],
    mustorselection: null,

    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    lessonCategories: null,
    lessonCategories2: null,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedLessonCategory: null,
  }),
  props: {
    title: String,
    description: String,
    childPath: String,
    directGo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },
    joinedCategories() {
      return this.lessonCategories;
    },
    filteredLessonCategories() {
      var list = [];
      if (this.joinedCategories != null) {
        var counter = 0;
        for (var item of this.joinedCategories) {
          if (this.selectedLessonType) {
            if (this.selectedLessonType.value == item.lessonType) {
              counter++;
              item.index = counter;
              list.push(item);
            }
          } else {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
        fixed: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "lessonType",
        fixed: true,
      },
      {
        text: "Эx үүсвэр",
        align: "start",
        sortable: true,
        value: "source",
        fixed: true,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];
    this.bodyNames = ["name"];
    this.mustorselection = fb.EYESH_APP ? 3 : 1;
    fb.db
      .collection("categories")
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.lessonCategories = [];
        docs.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          item.source = "Стандарт";

          this.lessonCategories.push(item);
        });
      });
    if (this.userData.school) {
      this.userData.school.ref
        .collection("categories")
        .where("deleted", "==", false)
        .orderBy("createdAt", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories2 = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.source = "Сургуулийн";
            item.index = counter;
            this.lessonCategories2.push(item);
          });
        });
    }
  },
  watch: {
    selectedLessonType(val) {
      console.log(val);
    },
  },
  methods: {
    _setFalse() {
      this.joinedCategories.forEach((doc) => {
        doc.ref.update({ deleted: false, ownedBySchool: false });
      });
    },
    _writeToFirebase(item) {
      if (this.userData.school) {
        console.log("saving to school categories own categories");
        item.ownedBySchool = true;
        item.deleted = false;
        this.userData.school.ref
          .collection("categories")
          .doc()
          .set(item)
          .then(() => {})
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      } else {
        console.log("saving to categories");
        item.ownedBySchool = false;
        item.deleted = false;
        fb.db
          .collection("categories")
          .doc()
          .set(item)
          .then(() => {})
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    },
    _addPreLessons(ttype) {
      var yes = confirm(
        "Та сайдын батлагдсан xичээлүүдийг нэмэxийг xүсэж байна уу?"
      );
      if (yes) {
        var x = {};
        x.lessonType = ttype.value;
        x.lessonTypeName = ttype.name;
        x.createdAt = new Date();
        x.description = null;
        if (ttype.value == 1) {
          this.zaavalLessons.forEach((doc) => {
            x.name = doc.name;
            this._writeToFirebase(x);
          });
        } else if (ttype.value == 2) {
          this.songonLessons.forEach((doc) => {
            x.name = doc;
            this._writeToFirebase(x);
          });
        } else if (ttype.value == 3) {
          this.eyeshLessons.forEach((doc) => {
            x.name = doc;
            this._writeToFirebase(x);
          });
        } else if (ttype.value == 4) {
          this.elementaryLessons.forEach((doc) => {
            x.name = doc.name;
            this._writeToFirebase(x);
          });
        }
      }
    },
    _getLessonTypeName(lessonType) {
      return this.lessonTypes.find((element) => element.value == lessonType);
    },
    _addNewItem() {
      this.newDialog = true;
    },
    _saveLessonCategories() {
      var counter = 0;
      for (var lessonCategory of this.lessonCategories) {
        var newLessonData = {
          name: lessonCategory.name,
          lessonType: lessonCategory.lessonType,
          lessonTypeName: lessonCategory.lessonTypeName,
          createdAt: new Date(),
        };
        fb.db
          .collection(this.lessonCategoriesPath)
          .doc(lessonCategory.ref.id)
          .set(newLessonData, { merge: true })
          .then(() => {
            console.log(++counter);
          });
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.update({ deleted: true }).then(() => {
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.lessonCategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        var x = {};
        x.name = this.editedItem.name;
        if (this.editedItem.description)
          x.description = this.editedItem.description;
        else x.description = null;
        x.createdAt = new Date();
        var xx = this._getLessonTypeName(this.mustorselection);
        if (xx) {
          x.lessonType = xx.value;
          x.lessonTypeName = xx.name;
        } else {
          x.lessonType = null;
          x.lessonTypeName = null;
        }

        if (this.editedIndex === -1) {
          console.log(console.log(this.userData.school));
          if (this.userData.school) {
            x.ownedBySchool = true;
            x.deleted = false;
            this.userData.school.ref
              .collection("categories")
              .doc()
              .set(x)
              .then(() => {
                this._close();
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          } else {
            x.ownedBySchool = false;
            x.deleted = false;
            fb.db
              .collection("categories")
              .doc()
              .set(x)
              .then(() => {
                this._close();
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },
  },
};
</script>
 